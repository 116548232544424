import { Component } from '@angular/core';
import { SubscriptionPlan } from '../../models/subscription-plan.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';

interface Options {
  name: string;
  active: boolean;
}

@Component({
  selector: 'app-edit-subscription-plan',
  templateUrl: './edit-subscription-plan.component.html',
  styleUrls: ['./edit-subscription-plan.component.scss'],
})
export class EditSubscriptionPlanComponent {
  public subscriptionPlan = new SubscriptionPlan();
  public inputGroup: FormGroup;
  public activeOptions: Options[];

  constructor(
    private primengConfig: PrimeNGConfig,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    this.activeOptions = [
      { name: 'Active', active: true },
      { name: 'Not Active', active: false },
    ];
    this.subscriptionPlan = config.data.row;
    console.log(this.subscriptionPlan);
    this.inputGroup = new FormGroup({
      description: new FormControl(this.subscriptionPlan.description, [
        Validators.required,
      ]),
      cost: new FormControl(this.subscriptionPlan.cost, [Validators.required]),
      isActive: new FormControl('', [Validators.required]),
    });

    if (this.subscriptionPlan.isActive) {
      this.inputGroup.get('isActive')?.setValue(this.activeOptions[0]);
    } else {
      this.inputGroup.get('isActive')?.setValue(this.activeOptions[1]);
    }
  }

  public editSubscriptionPlan(): void {
    let edited: SubscriptionPlan = new SubscriptionPlan();

    edited.description = this.inputGroup.get('description')?.value;
    edited.cost = this.inputGroup.get('cost')?.value;
    edited.isActive = this.inputGroup.get('isActive')?.value.active;
    edited.id = this.subscriptionPlan.id;
    edited.dateCreated = this.subscriptionPlan.dateCreated;
    edited.dateUpdated = this.subscriptionPlan.dateUpdated;
    edited.dbRowVersion = this.subscriptionPlan.dbRowVersion;
    this.ref.close(edited);
  }

  public cancelSubscriptionPlan(): void {
    this.ref.close();
  }
}
