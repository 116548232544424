import { Component } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Country } from './model/country.model';
import { DataService } from 'src/app/services/data.service';
import { PrimeNGConfig } from 'primeng/api';
import { NotificationService } from 'src/app/services/notification.service';
import { MentalHealthProfessional } from './model/mental-health-professional.model';
import { AddWellnessProfessionalComponent } from './components/add-wellness-professional/add-wellness-professional.component';

@Component({
  selector: 'app-wellness',
  templateUrl: './wellness.component.html',
  styleUrls: ['./wellness.component.scss'],
  providers: [DialogService],
})
export class WellnessComponent {
  public ref: DynamicDialogRef = new DynamicDialogRef();
  public countries: Country[];
  public users: MentalHealthProfessional[];

  constructor(
    public dataService: DataService,
    private primengConfig: PrimeNGConfig,
    public dialogService: DialogService,
    public notification: NotificationService
  ) {
    this.countries = [];
    this.users = [];
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.dataService
      .getAll<MentalHealthProfessional>('api/admin/wellness/Wellness/GetAll')
      .subscribe((users) => {
        this.notification.showSpinner.next(false);
        this.users = users;
      });

    this.dataService
      .getAll<Country>('api/admin/users/UserManagement/GetAllCountries')
      .subscribe((countries) => {
        this.notification.showSpinner.next(false);
        this.countries = countries;
      });
  }

  public showAddUser(): void {
    this.ref = this.dialogService.open(AddWellnessProfessionalComponent, {
      data: {
        countries: this.countries,
      },
      header: 'Add Wellness Professional',
      width: '70%',
      baseZIndex: 10000,
      maximizable: false,
    });

    this.ref.onClose.subscribe((newWellnessProfessional) => {
      if (newWellnessProfessional != null) {
        this.dataService
          .add(
            'api/admin/users/UserManagement/AddWellnessPortalUser',
            newWellnessProfessional
          )
          .subscribe(() => {
            this.notification.showSpinner.next(false);
            this.dataService
              .getAll<MentalHealthProfessional>(
                'api/admin/Wellness/Wellness/GetAll'
              )
              .subscribe((users) => {
                this.users = users;
                this.notification.showSpinner.next(false);
              });
          });
      }
    });
  }

  public swapEnableUser(row: MentalHealthProfessional): void {
    row.isActive = row.isActive ? false : true;

    this.dataService
      .update('api/admin/Wellness/Wellness/Edit', row)
      .subscribe(() => {
        this.notification.showSpinner.next(false);
        this.dataService
          .getAll<MentalHealthProfessional>(
            'api/admin/Wellness/Wellness/GetAll'
          )
          .subscribe((users) => {
            this.users = users;
            this.notification.showSpinner.next(false);
          });
      });
  }
}
