import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterComponent } from './footer/footer.component';
import { NavigationComponent } from './header/navigation/navigation.component';
import { AppSpinnerComponent } from './shared/spinner/spinner.component';
import { GlobalErrorHandlerService } from './services/global-error-handler.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { KidsprogressComponent } from './main/kidsprogress/kidsprogress.component';
import { WellnessComponent } from './main/wellness/wellness.component';
import { UsermanagementComponent } from './main/usermanagement/usermanagement.component';
import { WebportalComponent } from './main/webportal/webportal.component';
import { ButtonModule } from 'primeng/button';
import { MenubarModule } from 'primeng/menubar';
import { TableModule } from 'primeng/table';
import { EditComponent } from './main/usermanagement/components/edit-user/edit.component';
import { AvatarModule } from 'primeng/avatar';
import { DeleteComponent } from './main/usermanagement/components/delete-user/delete.component';
import { SendNotificationComponent } from './main/usermanagement/components/send-notification/send-notification.component';
import { InputTextModule } from 'primeng/inputtext';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { AddUserComponent } from './main/usermanagement/components/add-user/addUser.component';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SendMassNotificationComponent } from './main/usermanagement/components/send-mass-notification/send-mass-notification.component';
import { OverlayModule } from 'primeng/overlay';
import { AccordionModule } from 'primeng/accordion';
import { ActivitiesComponent } from './main/webportal/components/activities/activities.component';
import { CouponManagementComponent } from './main/webportal/components/coupon-management/coupon-management.component';
import { InspirationalMessagesComponent } from './main/webportal/components/inspirational-messages/inspirational-messages.component';
import { SubscriptionOptionsComponent } from './main/webportal/components/subscription-options/subscription-options.component';
import { SubscriptionPlansComponent } from './main/webportal/components/subscription-plans/subscription-plans.component';
import { AddInspirationalMessageComponent } from './main/webportal/components/add-inspirational-message/add-inspirational-message.component';
import { EditInspirationalMessageComponent } from './main/webportal/components/edit-inspirational-message/edit-inspirational-message.component';
import { DeleteInspirationalMessageComponent } from './main/webportal/components/delete-inspirational-message/delete-inspirational-message.component';
import { EditCouponComponent } from './main/webportal/components/edit-coupon/edit-coupon.component';
import { AddCouponComponent } from './main/webportal/components/add-coupon/add-coupon.component';
import { DeleteCouponComponent } from './main/webportal/components/delete-coupon/delete-coupon.component';
import { EditSubscriptionOptionComponent } from './main/webportal/components/edit-subscription-option/edit-subscription-option.component';
import { AddSubscriptionOptionComponent } from './main/webportal/components/add-subscription-option/add-subscription-option.component';
import { ViewPlanMatrixComponent } from './main/webportal/components/view-plan-matrix/view-plan-matrix.component';
import { MappingOptionsComponent } from './main/webportal/components/mapping-options/mapping-options.component';
import { DeleteSubscriptionPlanComponent } from './main/webportal/components/delete-subscription-plan/delete-subscription-plan.component';
import { EditSubscriptionPlanComponent } from './main/webportal/components/edit-subscription-plan/edit-subscription-plan.component';
import { AddSubscriptionPlanComponent } from './main/webportal/components/add-subscription-plan/add-subscription-plan.component';
import { AddActivityComponent } from './main/webportal/components/add-activity/add-activity.component';
import { EditActivityComponent } from './main/webportal/components/edit-activity/edit-activity.component';
import { EditKidsWellnessProfessionalComponent } from './main/kidsprogress/components/edit-kids-wellness-professional/edit-kids-wellness-professional.component';
import { ViewHistoryComponent } from './main/kidsprogress/components/view-history/view-history.component';
import { AddWellnessProfessionalComponent } from './main/wellness/components/add-wellness-professional/add-wellness-professional.component';
import { StatusPipe } from './pipes/status.pipe';
import { AddActivityTypeLookupComponent } from './main/webportal/components/add-activity-type-lookup/add-activity-type-lookup.component';
import { TagInputModule } from 'ngx-chips';
import { EntertainmentManagementComponent } from './main/webportal/components/entertainment-management/entertainment-management.component';
import { AddRewardComponent } from './main/webportal/components/add-reward/add-reward.component';
import { EditRewardComponent } from './main/webportal/components/edit-reward/edit-reward.component';
import { DeleteRewardComponent } from './main/webportal/components/delete-reward/delete-reward.component';
import { PaginatorModule } from 'primeng/paginator';
import { ParentsComponent } from './main/usermanagement/components/parents/parents.component';
import { EditChildUserComponent } from './main/usermanagement/components/edit-child-user/edit-child-user.component';
import { EditParentUserComponent } from './main/usermanagement/components/edit-parent-user/edit-parent-user.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavigationComponent,
    AppSpinnerComponent,
    PageNotFoundComponent,
    KidsprogressComponent,
    WellnessComponent,
    UsermanagementComponent,
    WebportalComponent,
    EditComponent,
    DeleteComponent,
    SendNotificationComponent,
    AddUserComponent,
    SendMassNotificationComponent,
    WebportalComponent,
    ActivitiesComponent,
    CouponManagementComponent,
    InspirationalMessagesComponent,
    SubscriptionOptionsComponent,
    SubscriptionPlansComponent,
    AddInspirationalMessageComponent,
    EditInspirationalMessageComponent,
    DeleteInspirationalMessageComponent,
    EditCouponComponent,
    AddCouponComponent,
    DeleteCouponComponent,
    EditSubscriptionOptionComponent,
    AddSubscriptionOptionComponent,
    ViewPlanMatrixComponent,
    MappingOptionsComponent,
    DeleteSubscriptionPlanComponent,
    EditSubscriptionPlanComponent,
    AddSubscriptionPlanComponent,
    AddActivityComponent,
    EditActivityComponent,
    EditKidsWellnessProfessionalComponent,
    ViewHistoryComponent,
    AddWellnessProfessionalComponent,
    StatusPipe,
    AddActivityTypeLookupComponent,
    EntertainmentManagementComponent,
    AddRewardComponent,
    EditRewardComponent,
    DeleteRewardComponent,
    ParentsComponent,
    EditChildUserComponent,
    EditParentUserComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    ButtonModule,
    MenubarModule,
    TableModule,
    AvatarModule,
    InputTextModule,
    DropdownModule,
    InputTextareaModule,
    OverlayModule,
    TagInputModule,
    PaginatorModule,
    AccordionModule,
    AuthModule.forRoot({
      domain: environment.authDomain,
      clientId: environment.authClientId,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: environment.authAudience,
        scope: environment.authScope,
      },
      httpInterceptor: {
        allowedList: [{ uri: `${environment.apiUrl}/*` }],
      },
    }),
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
