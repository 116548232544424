<form [formGroup]="inputGroup" (submit)="addReward()">
  <div class="row">
    <div class="col-sm-2">
      <label for="coupon">Coupon Code:</label>
    </div>
    <div class="col-sm-10">
      <input id="coupon" type="text" pInputText formControlName="coupon" />
      <small
        style="color: red"
        *ngIf="
          !inputGroup.get('coupon')?.valid &&
          (inputGroup.get('coupon')?.dirty || inputGroup.get('coupon')?.touched)
        "
        >Please enter a coupon code. It must be 4 or more characters.</small
      >
    </div>
  </div>

  <div class="row">
    <div class="col-sm-2">
      <label for="stars">Stars:</label>
    </div>
    <div class="col-sm-10">
      <input id="stars" pInputText type="number" formControlName="stars" />
      <small
        style="color: red"
        *ngIf="
          !inputGroup.get('stars')?.valid &&
          (inputGroup.get('stars')?.dirty || inputGroup.get('stars')?.touched)
        "
        >Please enter a valid Stars.</small
      >
    </div>
  </div>
  <div class="row">
    <div class="col-sm-2">
      <label for="description">Description:</label>
    </div>
    <div class="col-sm-10">
      <input
        id="description"
        type="text"
        pInputText
        formControlName="description"
      />
      <small
        style="color: red"
        *ngIf="
          !inputGroup.get('description')?.valid &&
          (inputGroup.get('description')?.dirty ||
            inputGroup.get('description')?.touched)
        "
        >Please enter description.</small
      >
    </div>
  </div>
  <div class="clearfix visible-xs"></div>
  <div class="to-right flex">
    <p-button
      label="Cancel"
      styleClass="p-button-danger"
      (onClick)="cancelAddReward()"
    ></p-button>
    <p-button
      label="Add Reward"
      type="Submit"
      [disabled]="!inputGroup.valid"
    ></p-button>
  </div>
</form>
