import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Coupon } from '../../models/coupon.model';
import { DatePipe } from '@angular/common';

interface TrueFalseOptions {
  name: string;
  isActive: boolean;
}

@Component({
  selector: 'app-add-coupon',
  templateUrl: './add-coupon.component.html',
  styleUrls: ['./add-coupon.component.scss'],
})
export class AddCouponComponent {
  public inputGroup: FormGroup;
  public trueFalse: TrueFalseOptions[];
  public datePipe = new DatePipe('en-US');

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    this.inputGroup = new FormGroup({
      couponCode: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
      ]),
      expiration: new FormControl('', [Validators.required]),
      value: new FormControl('', [Validators.required]),
      status: new FormControl('', [Validators.required]),
    });

    this.trueFalse = [
      { name: 'Active', isActive: true },
      { name: 'Not Active', isActive: false },
    ];
  }

  public addCoupon(): void {
    let newCoupon: Coupon = new Coupon();

    newCoupon.couponCode = this.inputGroup.get('couponCode')?.value;
    newCoupon.expirationDate = this.inputGroup.get('expiration')?.value;
    newCoupon.amount = this.inputGroup.get('value')?.value;
    newCoupon.isActive = this.inputGroup.get('status')?.value.isActive;

    this.ref.close(newCoupon);
  }

  public cancelAddCoupon(): void {
    this.ref.close();
  }
}
