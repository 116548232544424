<form [formGroup]="inputGroup" (submit)="addSubscriptionPlan()">
  <div class="row">
    <div class="col-sm-2">
      <label for="description">Name:</label>
    </div>
    <div class="col-sm-10">
      <input
        id="description"
        type="text"
        pInputText
        formControlName="description"
      />
      <small
        style="color: red"
        *ngIf="
          !inputGroup.get('description')?.valid &&
          (inputGroup.get('description')?.dirty ||
            inputGroup.get('description')?.touched)
        "
        >Please enter a name.</small
      >
    </div>
  </div>

  <div class="row">
    <div class="col-sm-2">
      <label for="isActive">Active:</label>
    </div>
    <div class="col-sm-10">
      <p-dropdown
        formControlName="isActive"
        [options]="activeOptions"
        optionLabel="name"
        [style]="{ width: '100%', marginBottom: '10px' }"
        placeholder="Select a Status"
      ></p-dropdown>
      <small
        style="color: red"
        *ngIf="
          !inputGroup.get('isActive')?.valid &&
          (inputGroup.get('isActive')?.dirty ||
            inputGroup.get('isActive')?.touched)
        "
        >Please enter if this subscription plan is active or not.</small
      >
    </div>
  </div>

  <div class="row">
    <div class="col-sm-2">
      <label for="cost">Cost:</label>
    </div>
    <div class="col-sm-10">
      <input id="cost" type="number" pInputText formControlName="cost" />
      <small
        style="color: red"
        *ngIf="
          !inputGroup.get('cost')?.valid &&
          (inputGroup.get('cost')?.dirty || inputGroup.get('cost')?.touched)
        "
        >Please enter a cost.</small
      >
    </div>
  </div>

  <div class="clearfix visible-xs"></div>
  <div class="to-right flex">
    <p-button
      label="Cancel"
      styleClass="p-button-danger"
      (onClick)="cancelSubscriptionPlan()"
    ></p-button>
    <p-button
      label="Add Subscription Plan"
      type="Submit"
      [disabled]="!inputGroup.valid"
    ></p-button>
  </div>
</form>
