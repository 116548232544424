import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Activity } from '../../models/activity.model';
import { DataService } from 'src/app/services/data.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ActivityTypeLookup } from '../../models/activityTypeLookups.model';

interface TrueFalseOptions {
  name: string;
  isActive: boolean;
}

@Component({
  selector: 'app-add-activity',
  templateUrl: './add-activity.component.html',
  styleUrls: ['./add-activity.component.scss'],
})
export class AddActivityComponent {
  private readonly MAX_IMAGE_SIZE_BYTES = 5 * 1024 * 1024;

  public inputGroup: FormGroup;
  public trueFalse: TrueFalseOptions[];
  public scheduleOptions: string[];
  public typeOptions: ActivityTypeLookup[];
  public daysOfWeek: string[];
  public daysOfMonth: number[];
  public selectedImage: any = null;
  formData = new FormData();

  constructor(
    public dataService: DataService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public notification: NotificationService
  ) {
    this.scheduleOptions = ['Daily', 'Weekly', 'Monthly'];

    this.typeOptions = config?.data.types;
    this.daysOfWeek = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];
    this.daysOfMonth = Array.from({ length: 31 }, (_, i) => i + 1);
    this.inputGroup = new FormGroup({
      name: new FormControl('', [Validators.required]),
      type: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      stars: new FormControl('', [Validators.required]),
      schedule: new FormControl('', [Validators.required]),
      status: new FormControl('', [Validators.required]),
      recipeSteps: new FormControl([]),
      videoUrl: new FormControl(''),
      dayOfWeek: new FormControl(''),
      dayOfMonth: new FormControl(''),
      question: new FormControl(''),
    });
    this.inputGroup.get('schedule')?.setValidators([Validators.required]);
    this.inputGroup.get('schedule')?.valueChanges.subscribe((schedule) => {
      const dayOfWeekControl = this.inputGroup.get('dayOfWeek');
      const dayOfMonthControl = this.inputGroup.get('dayOfMonth');

      if (schedule === 'Weekly') {
        dayOfWeekControl?.setValidators([Validators.required]);
        dayOfMonthControl?.clearValidators();
      } else if (schedule === 'Monthly') {
        dayOfWeekControl?.clearValidators();
        dayOfMonthControl?.setValidators([Validators.required]);
      } else {
        dayOfWeekControl?.clearValidators();
        dayOfMonthControl?.clearValidators();
      }

      dayOfWeekControl?.updateValueAndValidity();
      dayOfMonthControl?.updateValueAndValidity();
    });

    this.trueFalse = [
      { name: 'Active', isActive: true },
      { name: 'Not Active', isActive: false },
    ];
  }
  public onFileSelected(event: any): void {
    this.selectedImage = null;
    this.formData.delete('file');
    const file = event.target.files[0];
    if (file) {
      if (file.size > this.MAX_IMAGE_SIZE_BYTES) {
        this.notification.showWarningMessage(
          'Image size exceeds the maximum limit.',
          'Warning'
        );
        return;
      }

      const acceptedImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];
      if (!acceptedImageTypes.includes(file.type)) {
        this.notification.showWarningMessage(
          'Invalid file type. Please select an image file, i.e., jpeg, jpg or png',
          'Warning'
        );
        return;
      }
      this.selectedImage = URL.createObjectURL(file);
      this.formData.append('image', file);
    }
  }
  public addActivity(): void {
    let newActivity: Activity = new Activity();
    newActivity.name = this.inputGroup.get('name')?.value;
    newActivity.activityTypeLookupId = this.inputGroup.get('type')?.value?.id;
    newActivity.activityTypeDescription =
      this.inputGroup.get('description')?.value;
    newActivity.activityScheduleType = this.inputGroup.get('schedule')?.value;
    if (newActivity.activityScheduleType === 'Daily') {
      newActivity.dayOfMonth = undefined;
      newActivity.dayOfMonth = undefined;
    } else if (newActivity.activityScheduleType === 'Weekly') {
      newActivity.dayOfMonth = undefined;
      newActivity.dayOfWeek = this.inputGroup.get('dayOfWeek')?.value;
    } else if (newActivity.activityScheduleType === 'Monthly') {
      newActivity.dayOfWeek = undefined;
      newActivity.dayOfMonth = this.inputGroup.get('dayOfMonth')?.value;
    }
    if (this.inputGroup.get('type')?.value?.type === 'Nutrition') {
      newActivity.question = this.inputGroup.get('question')?.value;
    }
    newActivity.recipeStepsJson = undefined;
    if (
      this.inputGroup.get('type')?.value?.type === 'Nutrition' &&
      this.inputGroup.get('recipeSteps')?.value.length > 0
    ) {
      let stepsJson = JSON.stringify(this.inputGroup.get('recipeSteps')?.value);
      newActivity.recipeStepsJson = stepsJson;
    }
    newActivity.stars = this.inputGroup.get('stars')?.value;
    newActivity.isActive = this.inputGroup.get('status')?.value.isActive;
    newActivity.videoUrl = this.inputGroup.get('videoUrl')?.value;
    this.ref.close({ activity: newActivity, image: this.formData });
  }

  public cancelAddActivity(): void {
    this.ref.close();
  }
}
