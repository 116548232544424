import { Component } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { inspirationalMessage } from '../../models/inspirational-message.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-inspirational-message',
  templateUrl: './add-inspirational-message.component.html',
  styleUrls: ['./add-inspirational-message.component.scss'],
})
export class AddInspirationalMessageComponent {
  public newInspirationalMessage = new inspirationalMessage();
  public inputGroup: FormGroup;

  constructor(
    private primengConfig: PrimeNGConfig,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    this.inputGroup = new FormGroup({
      message: new FormControl('', [Validators.required]),
    });
  }

  public addInspirationalMessage(): void {
    this.newInspirationalMessage.isActive = true;
    this.newInspirationalMessage.message =
      this.inputGroup.get('message')?.value;
    this.ref.close(this.newInspirationalMessage);
  }

  public cancelAddMessage(): void {
    this.ref.close();
  }
}
