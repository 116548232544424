<div class="title">
  <h1 class="display-3">Web Portal</h1>
</div>
<div class="card">
  <p-accordion [multiple]="true" [activeIndex]="[0]">
    <p-accordionTab header="Activities">
      <app-activities></app-activities>
    </p-accordionTab>
    <p-accordionTab header="Subscription Plans"
      ><app-subscription-plans></app-subscription-plans
    ></p-accordionTab>
    <p-accordionTab header="Subscription Options"
      ><app-subscription-options></app-subscription-options
    ></p-accordionTab>
    <p-accordionTab header="Inspirational Messages"
      ><app-inspirational-messages></app-inspirational-messages
    ></p-accordionTab>
    <p-accordionTab header="Coupon Management"
      ><app-coupon-management></app-coupon-management
    ></p-accordionTab>
    <p-accordionTab header="Entertainment Rewards Management"
      ><app-entertainment-management></app-entertainment-management
    ></p-accordionTab>
  </p-accordion>
</div>
