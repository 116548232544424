<div class="title">
  <h1 class="display-3">Kid's Progress</h1>
</div>
<div class="card">
  <p-table
    #dt2
    [value]="kids"
    dataKey="id"
    [rows]="10"
    styleClass="p-datatable-users p-datatable-striped"
    [globalFilterFields]="[
      'username',
      'firstName',
      'lastName',
      'wellnessProfessionalFirstName',
      'wellnessProfessionalLastName'
    ]"
    [scrollable]="true"
    scrollHeight="1000px"
    dataKey="message"
    [tableStyle]="{ 'min-width': '50rem' }"
  >
    <ng-template pTemplate="caption">
      <div class="flex">
        <span class="p-input-icon-right to-right">
          <i class="pi pi-search to-right"></i>
          <input
            pInputText
            type="text"
            (input)="dt2.filterGlobal($any($event.target).value, 'contains')"
            placeholder="Search keyword"
          />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th id="message" class="table-col-width-small">
          <div class="p-d-flex p-jc-between p-ai-center">Username</div>
        </th>
        <th id="lastName" class="table-col-width-small">
          <div class="p-d-flex p-jc-between p-ai-center">First Name</div>
        </th>
        <th id="lastName" class="table-col-width-small">
          <div class="p-d-flex p-jc-between p-ai-center">Last Name</div>
        </th>
        <th id="lastName" class="table-col-width-small">
          <div class="p-d-flex p-jc-between p-ai-center">Assigned To</div>
        </th>
        <th id="actions" class="table-col-width-large">
          <div class="p-d-flex p-jc-between p-ai-center">Actions</div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-kid>
      <tr>
        <td>
          <span>{{ kid.username }}</span>
        </td>
        <td>
          <span>{{ kid.firstName }}</span>
        </td>
        <td>
          <span>{{ kid.lastName }}</span>
        </td>
        <td>
          <span>{{
            kid.wellnessProfessionalFirstName +
              " " +
              kid.wellnessProfessionalLastName
          }}</span>
        </td>
        <td>
          <p-button
            label="Edit"
            styleClass="p-button-warning p-button-sm"
            icon="pi pi-file-edit"
            iconPos="right"
            (onClick)="showEditKid(kid)"
          ></p-button>
          <p-button
            label="View History"
            styleClass="p-button p-button-sm"
            icon="pi pi-trash"
            iconPos="right"
            (onClick)="showViewHistory(kid)"
          ></p-button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7">No kids found.</td>
      </tr>
    </ng-template>
  </p-table>
</div>
