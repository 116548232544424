import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss'],
})
export class DeleteComponent {
  constructor(public ref: DynamicDialogRef) {}

  public deleteCommand(action: boolean): void {
    this.ref.close(action);
  }
}
