import { Component } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-delete-reward',
  templateUrl: './delete-reward.component.html',
  styleUrls: ['./delete-reward.component.scss'],
})
export class DeleteRewardComponent {
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  public deleteReward(): void {
    this.ref.close(true);
  }

  public cancelDelete(): void {
    this.ref.close(false);
  }
}
