export class User {
  public auth0UserId?: string;
  public id?: number;
  public portal?: string;
  public username?: string;
  public firstName?: string;
  public lastName?: string;
  public email?: string;
  public address1?: string;
  public address2?: string;
  public address3?: string;
  public address4?: string;
  public city?: string;
  public postalCode?: string;
  public countryId?: number;
  public dbRowVersion?: string;
  public lastLogin?: Date;
  public gender?: string;
  public dateOfBirth?: Date;
  public starsEarned?: number;
  public parentId?: number;
  public subscriptionId?: number;
  public mentalHealthProId?: number;
  public isWellnessOptionSelected?: boolean;
  public isActive?: boolean;
  public isDisabled?: boolean;
  public subscriptionExpiration?: Date;
  public evaluationDueDate?: Date;
  public lastEvaluated?: Date;
}
