import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-delete-inspirational-message',
  templateUrl: './delete-inspirational-message.component.html',
  styleUrls: ['./delete-inspirational-message.component.scss'],
})
export class DeleteInspirationalMessageComponent {
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  public deleteInspirationalMessage(): void {
    this.ref.close(true);
  }

  public cancelDelete(): void {
    this.ref.close(false);
  }
}
