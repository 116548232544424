export class UserProfile {
  public userId?: string;
  public username?: string;
  public firstName?: string;
  public lastName?: string;
  public email?: string;
  public role?: string;
  public initials?: string;
  public isEmployee: boolean = false;

  constructor(
    userId?: string,
    username?: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    initials?: string,
    role?: string
  ) {
    this.userId = userId;
    this.username = username;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.initials = initials;
    this.role = role;
  }
}
