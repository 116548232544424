import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { inspirationalMessage } from '../../models/inspirational-message.model';

interface Options {
  name: string;
  active: boolean;
}

@Component({
  selector: 'app-edit-inspirational-message',
  templateUrl: './edit-inspirational-message.component.html',
  styleUrls: ['./edit-inspirational-message.component.scss'],
})
export class EditInspirationalMessageComponent {
  public inputGroup: FormGroup;
  public inspirationalMessage: inspirationalMessage;
  public activeOptions: Options[];

  constructor(
    private primengConfig: PrimeNGConfig,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    this.activeOptions = [
      { name: 'Active', active: true },
      { name: 'Not Active', active: false },
    ];
    this.inspirationalMessage = config.data.inspirationalMessage;
    this.inputGroup = new FormGroup({
      message: new FormControl(this.inspirationalMessage.message, [
        Validators.required,
      ]),
      isActive: new FormControl('', [Validators.required]),
    });

    if (this.inspirationalMessage.isActive) {
      this.inputGroup.get('isActive')?.setValue(this.activeOptions[0]);
    } else {
      this.inputGroup.get('isActive')?.setValue(this.activeOptions[1]);
    }
  }

  public editInspirationalMessage(): void {
    let edited: inspirationalMessage = new inspirationalMessage();

    edited.message = this.inputGroup.get('message')?.value;
    edited.isActive = this.inputGroup.get('isActive')?.value.active;
    edited.id = this.inspirationalMessage.id;
    edited.dbRowVersion = this.inspirationalMessage.dbRowVersion;
    this.ref.close(edited);
  }

  public cancelEditMessage(): void {
    this.ref.close();
  }
}
