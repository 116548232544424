import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DataService } from 'src/app/services/data.service';
import { PrimeNGConfig } from 'primeng/api';
import { NotificationService } from 'src/app/services/notification.service';
import { Activity } from '../../models/activity.model';
import { AddActivityComponent } from '../add-activity/add-activity.component';
import { EditActivityComponent } from '../edit-activity/edit-activity.component';
import { AddActivityTypeLookupComponent } from '../add-activity-type-lookup/add-activity-type-lookup.component';
import { ActivityTypeLookup } from '../../models/activityTypeLookups.model';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss'],
})
export class ActivitiesComponent implements OnInit {
  public ref: DynamicDialogRef = new DynamicDialogRef();
  public activities: Activity[];
  public activityTypes: ActivityTypeLookup[];

  constructor(
    public dataService: DataService,
    private primengConfig: PrimeNGConfig,
    public dialogService: DialogService,
    public notification: NotificationService
  ) {
    this.activities = [];
    this.activityTypes = [];
  }

  ngOnInit(): void {
    this.getAllActivities();
    this.dataService
      .getAll<ActivityTypeLookup>(
        'api/admin/WebPortal/Activities/GetAllActivityTypeLookups'
      )
      .subscribe((types) => {
        this.activityTypes = types;
        this.notification.showSpinner.next(false);
      });
  }

  private getAllActivities(): void {
    this.dataService
      .getAll<Activity>('api/admin/WebPortal/Activities/GetAll')
      .subscribe((activities) => {
        this.activities = activities;
        this.notification.showSpinner.next(false);
      });
  }
  public showAddActivity(): void {
    this.ref = this.dialogService.open(AddActivityComponent, {
      data: {
        types: this.activityTypes,
      },
      header: 'Add Activity',
      width: '70%',
      baseZIndex: 10000,
      maximizable: false,
    });
    this.ref.onClose.subscribe((newEntry) => {
      if (newEntry != null) {
        let newActivity: Activity = newEntry.activity;
        newActivity.dbRowVersion = '';
        this.dataService
          .add('api/admin/WebPortal/Activities/Add', newActivity)
          .subscribe((res: any) => {
            console.log('Response', res);
            if (newEntry.image.has('image')) {
              let formData = new FormData();
              formData.append('file', newEntry.image.get('image'));
              console.log('Image Exists');
              this.dataService
                .addFile(
                  `api/admin/WebPortal/Activities/AddActivityImage/${res}`,
                  formData
                )
                .subscribe(() => {
                  this.getAllActivities();
                });
            } else {
              this.getAllActivities();
            }
          });
      }
    });
  }

  public showAddActivityTypeLookup(): void {
    this.ref = this.dialogService.open(AddActivityTypeLookupComponent, {
      header: 'Add Activity Type',
      width: '25%',
      baseZIndex: 10000,
      maximizable: false,
    });

    this.ref.onClose.subscribe((newEntry) => {
      if (newEntry != null) {
        let type = newEntry;
        this.dataService
          .add('api/admin/WebPortal/Activities/AddActivityTypeLookup', {
            id: 0,
            type: type,
          })
          .subscribe(() => {
            this.notification.showSpinner.next(false);
            this.dataService
              .getAll<ActivityTypeLookup>(
                'api/admin/WebPortal/Activities/GetAllActivityTypeLookups'
              )
              .subscribe((types) => {
                this.activityTypes = types;
                this.notification.showSpinner.next(false);
              });
          });
      }
    });
  }
  public showEditActivity(row: Activity): void {
    this.ref = this.dialogService.open(EditActivityComponent, {
      data: {
        activity: row,
        types: this.activityTypes,
      },
      header: 'Edit Activity',
      width: '70%',
      baseZIndex: 10000,
      maximizable: false,
    });

    this.ref.onClose.subscribe((edited) => {
      if (edited != null) {
        this.dataService
          .update('api/admin/WebPortal/Activities/Edit', edited.activity)
          .subscribe(() => {
            this.notification.showSpinner.next(false);
            if (edited.image.has('image')) {
              let formData = new FormData();
              formData.append('file', edited.image.get('image'));
              this.dataService
                .addFile(
                  `api/admin/WebPortal/Activities/AddActivityImage/${row.activityTypeId}`,
                  formData
                )
                .subscribe(() => {
                  this.getAllActivities();
                });
            } else {
              this.getAllActivities();
            }
          });
      }
    });
  }

  public swapEnableActivity(row: Activity): void {
    row.isActive = !row.isActive;
    const DtoSwapEnableActivityRequest = {
      ActivityMatrixId: row.activityMatrixId,
      IsActive: row.isActive,
    };
    this.dataService
      .update(
        'api/admin/WebPortal/Activities/SwapEnableActivity',
        DtoSwapEnableActivityRequest
      )
      .subscribe(() => {
        this.notification.showSpinner.next(false);
        this.getAllActivities();
      });
  }
  public assignActivities(): void {
    this.dataService
      .getAll('api/admin/WebPortal/Activities/AssignActivities')
      .subscribe(() => {
        this.notification.showSpinner.next(false);
      });
  }
  public getType(id: number): string {
    return this.activityTypes.find((item) => item.id === id)?.type ?? '';
  }
}
