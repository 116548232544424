<p-table
  #dt2
  [value]="subscriptionsWithTextOptions"
  dataKey="id"
  [rows]="10"
  styleClass="p-datatable-users p-datatable-striped"
  [globalFilterFields]="['plan', 'option']"
  [scrollable]="true"
  scrollHeight="1000px"
  dataKey="plan"
  [tableStyle]="{ 'min-width': '50rem' }"
>
  <ng-template pTemplate="caption">
    <div class="flex">
      <span class="p-input-icon-right to-right">
        <i class="pi pi-search to-right"></i>
        <input
          pInputText
          type="text"
          (input)="dt2.filterGlobal($any($event.target).value, 'contains')"
          placeholder="Search keyword"
        />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th id="plan" class="table-col-width-small">
        <div class="p-d-flex p-jc-between p-ai-center">Plan</div>
      </th>
      <th id="option" class="table-col-width-small">
        <div class="p-d-flex p-jc-between p-ai-center">Option</div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-subscriptionWithOption>
    <tr>
      <td>
        <span>{{ subscriptionWithOption.plan }}</span>
      </td>
      <td>
        <span>{{ subscriptionWithOption.options }}</span>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7">No activities found.</td>
    </tr>
  </ng-template>
</p-table>
