import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Coupon } from '../../models/coupon.model';
import { DataService } from 'src/app/services/data.service';
import { PrimeNGConfig } from 'primeng/api';
import { NotificationService } from 'src/app/services/notification.service';
import { AddCouponComponent } from '../add-coupon/add-coupon.component';
import { EditCouponComponent } from '../edit-coupon/edit-coupon.component';
import { DeleteCouponComponent } from '../delete-coupon/delete-coupon.component';
import { StatusPipe } from 'src/app/pipes/status.pipe';
import { Entertainment } from '../../models/entertainment.model';
import { AddRewardComponent } from '../add-reward/add-reward.component';
import { EditRewardComponent } from '../edit-reward/edit-reward.component';
import { DeleteRewardComponent } from '../delete-reward/delete-reward.component';

@Component({
  selector: 'app-entertainment-management',
  templateUrl: './entertainment-management.component.html',
  styleUrls: ['./entertainment-management.component.scss'],
})
export class EntertainmentManagementComponent implements OnInit {
  public ref: DynamicDialogRef = new DynamicDialogRef();
  public entertainmentRewards: Entertainment[];

  constructor(
    public dataService: DataService,
    private primengConfig: PrimeNGConfig,
    public dialogService: DialogService,
    public notification: NotificationService
  ) {
    this.entertainmentRewards = [];
  }

  ngOnInit(): void {
    this.getAllRewards();
  }

  public showAddReward(): void {
    this.ref = this.dialogService.open(AddRewardComponent, {
      header: 'Add Reward',
      width: '70%',
      baseZIndex: 10000,
      maximizable: false,
    });

    this.ref.onClose.subscribe((newReward) => {
      if (newReward != null) {
        this.dataService
          .add('api/admin/WebPortal/EntertainmentManagement/Add', newReward)
          .subscribe(() => {
            this.notification.showSpinner.next(false);
            this.getAllRewards();
          });
      }
    });
  }

  private getAllRewards() {
    this.dataService
      .getAll<Entertainment>(
        'api/admin/WebPortal/EntertainmentManagement/GetAll'
      )
      .subscribe((rewards) => {
        this.entertainmentRewards = rewards;
        this.notification.showSpinner.next(false);
      });
  }

  public showEditReward(reward: Entertainment): void {
    this.ref = this.dialogService.open(EditRewardComponent, {
      data: {
        reward: reward,
      },
      header: 'Edit Reward',
      width: '70%',
      baseZIndex: 10000,
      maximizable: false,
    });

    this.ref.onClose.subscribe((edited) => {
      if (edited != null) {
        this.dataService
          .update('api/admin/WebPortal/EntertainmentManagement/Edit', edited)
          .subscribe(() => {
            this.notification.showSpinner.next(false);
            this.getAllRewards();
          });
      }
    });
  }

  public swapEnableReward(row: Entertainment): void {
    row.isActive = row.isActive ? false : true;

    this.dataService
      .update('api/admin/WebPortal/EntertainmentManagement/Edit', row)
      .subscribe(() => {
        this.notification.showSpinner.next(false);
        this.getAllRewards();
      });
  }

  public showDeleteReward(coupon: Coupon): void {
    this.ref = this.dialogService.open(DeleteRewardComponent, {
      header: 'Delete Coupon',
      width: '70%',
      baseZIndex: 10000,
      maximizable: false,
    });

    this.ref.onClose.subscribe((successfulDelete) => {
      if (successfulDelete) {
        this.dataService
          .deleteById(
            'api/admin/WebPortal/EntertainmentManagement/Delete',
            coupon.id as number
          )
          .subscribe(() => {
            this.notification.showSpinner.next(false);
            this.getAllRewards();
          });
      }
    });
  }
}
