import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { UserService } from 'src/app/services/User.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  public items!: MenuItem[];
  public overlayVisible: boolean = false;

  constructor(public userService: UserService) {}

  ngOnInit() {
    this.items = [
      {
        label: 'User Management',
        icon: 'pi pi-fw pi-users',
        routerLink: '/usermanagement',
        styleClass: 'menuClass',
        id: 'usermanagementnavbutton',
      },
      {
        label: 'Web Portal',
        icon: 'pi pi-fw pi-share-alt',
        routerLink: '/webportal',
        styleClass: 'menuClass',
        id: 'webportalnavbutton',
      },
      {
        label: 'kids Progress',
        icon: 'pi pi-fw pi-chart-line',
        routerLink: '/kidsprogress',
        styleClass: 'menuClass',
        id: 'kidsprogressnavbutton',
      },
      {
        label: 'Wellness Professionals',
        icon: 'pi pi-fw pi-id-card',
        routerLink: '/wellness',
        styleClass: 'menuClass',
        id: 'wellnessnavbutton',
      },
      //Only for mobile view, desktop button shows in html file
      {
        label: 'Logout',
        icon: 'pi pi-fw pi-sign-out',
        command: () => this.userService.logout(),
        styleClass: 'menuClass mobileOnly',
        id: 'logoutbutton',
      },
    ];
  }

  public toggleLogout(): void {
    this.overlayVisible = !this.overlayVisible;
  }
}
