<form [formGroup]="inputGroup" (submit)="addCoupon()">
  <div class="row">
    <div class="col-sm-2">
      <label for="couponCode">Coupon Code:</label>
    </div>
    <div class="col-sm-10">
      <input
        id="couponCode"
        type="text"
        pInputText
        formControlName="couponCode"
      />
      <small
        style="color: red"
        *ngIf="
          !inputGroup.get('couponCode')?.valid &&
          (inputGroup.get('couponCode')?.dirty ||
            inputGroup.get('couponCode')?.touched)
        "
        >Please enter a coupon code. It must be 4 or more characters.</small
      >
    </div>
  </div>

  <div class="row">
    <div class="col-sm-2">
      <label for="status">Status:</label>
    </div>
    <div class="col-sm-10">
      <p-dropdown
        formControlName="status"
        [options]="trueFalse"
        optionLabel="name"
        placeholder="Choose Status"
        [style]="{ width: '100%', marginBottom: '10px' }"
      ></p-dropdown>
      <small
        style="color: red"
        *ngIf="
          !inputGroup.get('status')?.valid &&
          (inputGroup.get('status')?.dirty || inputGroup.get('status')?.touched)
        "
        >Please enter a valid status.</small
      >
    </div>
  </div>

  <div class="row">
    <div class="col-sm-2">
      <label for="expiration">Expiration Date:</label>
    </div>
    <div class="col-sm-10">
      <input
        id="expiration"
        type="date"
        pInputText
        formControlName="expiration"
      />
      <small
        style="color: red"
        *ngIf="
          !inputGroup.get('expiration')?.valid &&
          (inputGroup.get('expiration')?.dirty ||
            inputGroup.get('expiration')?.touched)
        "
        >Please enter an expiration date.</small
      >
    </div>
  </div>

  <div class="row">
    <div class="col-sm-2">
      <label for="value">Value:</label>
    </div>
    <div class="col-sm-10">
      <input id="value" pInputText type="money" formControlName="value" />
      <small
        style="color: red"
        *ngIf="
          !inputGroup.get('value')?.valid &&
          (inputGroup.get('value')?.dirty || inputGroup.get('value')?.touched)
        "
        >Please enter a valid value.</small
      >
    </div>
  </div>

  <div class="clearfix visible-xs"></div>
  <div class="to-right flex">
    <p-button
      label="Cancel"
      styleClass="p-button-danger"
      (onClick)="cancelAddCoupon()"
    ></p-button>
    <p-button
      label="Add Coupon"
      type="Submit"
      [disabled]="!inputGroup.valid"
    ></p-button>
  </div>
</form>
