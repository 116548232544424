import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-delete-subscription-plan',
  templateUrl: './delete-subscription-plan.component.html',
  styleUrls: ['./delete-subscription-plan.component.scss'],
})
export class DeleteSubscriptionPlanComponent {
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  public deleteSubscriptionPlan(): void {
    this.ref.close(true);
  }

  public cancelDelete(): void {
    this.ref.close(false);
  }
}
