import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'status' })
export class StatusPipe implements PipeTransform {
  transform(boolStatus: boolean): string {
    if (boolStatus) {
      return 'Active';
    } else {
      return 'Disabled';
    }
  }
}
