import { Component, OnInit } from '@angular/core';
import { getUsersRequest } from '../../model/getUsersRequest';
import { ChildDto, ParentDto } from '../../model/userDto.model';
import { Country } from '../../model/country.model';
import { PrimeNGConfig } from 'primeng/api';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { DataService } from 'src/app/services/data.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserServiceResponse } from '../../model/userServiceResponse.model';
import { AddUserComponent } from '../add-user/addUser.component';
import { DeleteComponent } from '../delete-user/delete.component';
import { EditComponent } from '../edit-user/edit.component';
import { SendMassNotificationComponent } from '../send-mass-notification/send-mass-notification.component';
import { SendNotificationComponent } from '../send-notification/send-notification.component';
import { User } from '../../model/user.model';
import { EditParentUserComponent } from '../edit-parent-user/edit-parent-user.component';

@Component({
  selector: 'app-parents',
  templateUrl: './parents.component.html',
  styleUrls: ['./parents.component.scss'],
})
export class ParentsComponent implements OnInit {
  userRequest: getUsersRequest = {
    PageNumber: 1,
    PageSize: 10,
    SearchText: '',
    TotalCount: 0,
  };
  public parents: ParentDto[];
  public countries: Country[];
  public backEndResponse = new UserServiceResponse();
  public ref: DynamicDialogRef = new DynamicDialogRef();

  constructor(
    public dataService: DataService,
    private primengConfig: PrimeNGConfig,
    public dialogService: DialogService,
    public notification: NotificationService
  ) {
    this.parents = [];
    this.countries = [];
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.getAllUsers();
    this.dataService
      .getAll<Country>('api/admin/users/UserManagement/GetAllCountries')
      .subscribe((countries) => {
        this.notification.showSpinner.next(false);
        this.countries = countries;
      });
  }

  public showEdit(userFromRow: ParentDto): void {
    this.ref = this.dialogService.open(EditParentUserComponent, {
      data: {
        user: userFromRow,
      },
      header: 'Edit User',
      width: '70%',
      baseZIndex: 10000,
      maximizable: false,
    });
  }
  public showSwapDisable(userFromRow: ParentDto): void {
    if (userFromRow.isDisabled === false) {
      this.ref = this.dialogService.open(DeleteComponent, {
        header: 'Disable/Enable User',
        width: '70%',
        baseZIndex: 10000,
        maximizable: false,
      });

      this.ref.onClose.subscribe((res) => {
        if (res === true) {
          this.SwapIsDisabledUer(userFromRow.id);
        }
      });
    } else {
      this.SwapIsDisabledUer(userFromRow.id);
    }
  }

  private SwapIsDisabledUer(userId: number): void {
    this.dataService
      .disableUserById(
        `api/admin/users/UserManagement/SwapIsDisableParentPortalUser`,
        userId
      )
      .subscribe((data) => {
        this.notification.showSpinner.next(false);
        this.getAllUsers();
      });
  }

  private getAllUsers(): void {
    this.dataService
      .add('api/admin/users/UserManagement/GetAllParents', this.userRequest)
      .subscribe((res: any) => {
        this.notification.showSpinner.next(false);
        this.parents = res;
        if (res && res.length > 0) {
          this.userRequest.TotalCount = res[0].totalCount;
        } else {
          this.userRequest.TotalCount = 0;
          this.userRequest.PageNumber = 1;
          this.userRequest.PageSize = 10;
        }
      });
  }
  public showSendNotification(userFromRow: User): void {
    this.ref = this.dialogService.open(SendNotificationComponent, {
      data: {
        user: userFromRow,
      },
      header: 'Send Notification',
      width: '70%',
      baseZIndex: 10000,
      maximizable: false,
    });
  }

  public showSendMassNotification(): void {
    this.ref = this.dialogService.open(SendMassNotificationComponent, {
      data: {
        users: this.parents,
      },
      header: 'Send Notification',
      width: '70%',
      baseZIndex: 10000,
      maximizable: false,
    });
  }

  public showAddUser(): void {
    this.ref = this.dialogService.open(AddUserComponent, {
      data: {
        countries: this.countries,
      },
      header: 'Create a parent user',
      width: '70%',
      baseZIndex: 10000,
      maximizable: false,
    });

    this.ref.onClose.subscribe((newUser) => {
      // this.users.push(newUser);
    });
  }

  handlePagination(event: any) {
    this.userRequest.PageNumber = event.page + 1;
    this.getAllUsers();
  }

  public onInputChange(searchText: string): void {
    this.userRequest.SearchText = searchText;
    this.userRequest.PageNumber = 1;
    this.getAllUsers();
  }
  public onClearSearch(searchText: string): void {
    if (searchText.length === 0) {
      this.userRequest.SearchText = '';
      this.userRequest.PageNumber = 1;
      this.getAllUsers();
    }
  }
}
