import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { User } from '../../model/user.model';
import { DataService } from 'src/app/services/data.service';
import { EmailRecipient } from '../../model/email-recipient.model';
import { EmailAddress } from '../../model/email-address.model';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-send-notification',
  templateUrl: './send-notification.component.html',
  styleUrls: ['./send-notification.component.scss'],
})
export class SendNotificationComponent {
  public user!: User;
  public message: string;
  public subject: string;
  public messageGroup: FormGroup;
  public header!: string;

  constructor(
    public dataservice: DataService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public notification: NotificationService
  ) {
    this.messageGroup = new FormGroup({
      subject: new FormControl('', [Validators.required]),
      message: new FormControl('', [Validators.required]),
    });
    this.user = config.data.user;
    this.message = '';
    this.subject = '';
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.header = 'Edit user: ';
      this.config.header = this.header.concat(
        this.user.firstName!,
        ' ',
        this.user.lastName!
      );
    });
  }

  public cancelSend(): void {
    this.ref.close();
  }

  public sendMessage(): void {
    this.message = this.messageGroup.get('message')?.value;
    this.subject = this.messageGroup.get('subject')?.value;
    const to: EmailAddress[] = [];
    to.push(new EmailAddress(this.user.email ?? '', this.user.email ?? ''));
    const recipient: EmailRecipient = new EmailRecipient(to);

    this.dataservice
      .add<EmailRecipient>(
        `api/common/notification/Send/${this.subject}/${this.message}`,
        recipient
      )
      .subscribe((data) => {
        this.notification.showSpinner.next(false);
        this.ref.close();
      });
  }

  public onSubjectKeyUp(args: any): void {
    this.messageGroup.get('subject')?.setValue(args);
  }

  public onMessageKeyUp(args: any): void {
    this.messageGroup.get('message')?.setValue(args);
  }
}
