<form [formGroup]="inputGroup" (submit)="editCoupon()">
  <div class="row">
    <div class="col-sm-2">
      <label for="couponCode">Coupon Code:</label>
    </div>
    <div class="col-sm-10">
      <input
        id="couponCode"
        type="text"
        pInputText
        formControlName="couponCode"
      />
      <small
        style="color: red"
        *ngIf="
          !inputGroup.get('couponCode')?.valid &&
          inputGroup.get('couponCode')?.dirty
        "
        >Please enter a coupon code.</small
      >
    </div>
  </div>

  <div class="row">
    <div class="col-sm-2">
      <label for="isActive">Status:</label>
    </div>
    <div class="col-sm-10">
      <p-dropdown
        formControlName="isActive"
        [options]="activeOptions"
        optionLabel="name"
        placeholder="Choose Status"
        [style]="{ width: '100%', marginBottom: '10px' }"
      ></p-dropdown>
      <small
        style="color: red"
        *ngIf="
          !inputGroup.get('isActive')?.valid &&
          inputGroup.get('isActive')?.dirty
        "
        >Please enter a valid status.</small
      >
    </div>
  </div>

  <div class="row">
    <div class="col-sm-2">
      <label for="expirationDate">Expiration Date:</label>
    </div>
    <div class="col-sm-10">
      <input
        id="expirationDate"
        type="date"
        pInputText
        formControlName="expirationDate"
      />
      <small
        style="color: red"
        *ngIf="
          !inputGroup.get('expirationDate')?.valid &&
          inputGroup.get('expirationDate')?.dirty
        "
        >Please enter an expiration date.</small
      >
    </div>
  </div>

  <div class="row">
    <div class="col-sm-2">
      <label for="amount">Value:</label>
    </div>
    <div class="col-sm-10">
      <input id="amount" pInputText formControlName="amount" />
      <small
        style="color: red"
        *ngIf="
          !inputGroup.get('amount')?.valid && inputGroup.get('amount')?.dirty
        "
        >Please enter a valid value.</small
      >
    </div>
  </div>

  <div class="clearfix visible-xs"></div>
  <div class="to-right flex">
    <p-button
      label="Cancel"
      styleClass="p-button-danger"
      (onClick)="cancelEditCoupon()"
    ></p-button>
    <p-button
      label="Edit Coupon"
      type="Submit"
      [disabled]="!inputGroup.valid"
    ></p-button>
  </div>
</form>
