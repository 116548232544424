import { Component } from '@angular/core';
import { SubscriptionOption } from '../../models/subscription-option.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-edit-subscription-option',
  templateUrl: './edit-subscription-option.component.html',
  styleUrls: ['./edit-subscription-option.component.scss'],
})
export class EditSubscriptionOptionComponent {
  public inputGroup: FormGroup;
  public subscriptionOption: SubscriptionOption = new SubscriptionOption();

  constructor(
    private primengConfig: PrimeNGConfig,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    this.subscriptionOption = config.data.row;
    this.inputGroup = new FormGroup({
      text: new FormControl(this.subscriptionOption.text, [
        Validators.required,
      ]),
    });
  }

  public editSubscriptionOption(): void {
    let edited: SubscriptionOption = new SubscriptionOption();

    edited.text = this.inputGroup.get('text')?.value;
    edited.isActive = this.subscriptionOption.isActive;
    edited.id = this.subscriptionOption.id;
    edited.dbRowVersion = this.subscriptionOption.dbRowVersion;
    this.ref.close(edited);
  }

  public cancelEditSubscriptionOption(): void {
    this.ref.close();
  }
}
