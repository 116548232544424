import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Entertainment } from '../../models/entertainment.model';
import { PrimeNGConfig } from 'primeng/api';
import {
  DialogService,
  DynamicDialogRef,
  DynamicDialogConfig,
} from 'primeng/dynamicdialog';
import { Coupon } from '../../models/coupon.model';

@Component({
  selector: 'app-edit-reward',
  templateUrl: './edit-reward.component.html',
  styleUrls: ['./edit-reward.component.scss'],
})
export class EditRewardComponent {
  public inputGroup: FormGroup;
  public reward: Entertainment;

  constructor(
    private primengConfig: PrimeNGConfig,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    this.reward = config.data.reward;
    this.inputGroup = new FormGroup({
      coupon: new FormControl(this.reward.coupon, [
        Validators.required,
        Validators.minLength(4),
      ]),
      stars: new FormControl(this.reward.stars, [Validators.required]),
      description: new FormControl(this.reward.description, [
        Validators.required,
      ]),
    });
  }

  public EditReward(): void {
    let edited: Entertainment = {
      stars: 0,
      coupon: '',
      description: '',
    };
    edited.coupon = this.inputGroup.get('coupon')?.value;
    edited.description = this.inputGroup.get('description')?.value;
    edited.stars = this.inputGroup.get('stars')?.value;
    edited.id = this.reward.id;
    edited.isActive = this.reward.isActive;
    this.ref.close(edited);
  }

  public cancelEditReward(): void {
    this.ref.close();
  }
}
