import { Component } from '@angular/core';
import { SubscriptionOption } from '../../models/subscription-option.model';
import { DataService } from 'src/app/services/data.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PrimeNGConfig } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AddSubscriptionOptionComponent } from '../add-subscription-option/add-subscription-option.component';
import { EditSubscriptionOptionComponent } from '../edit-subscription-option/edit-subscription-option.component';
import { ViewPlanMatrixComponent } from '../view-plan-matrix/view-plan-matrix.component';
import { MappingOptionsComponent } from '../mapping-options/mapping-options.component';
import { SubscriptionOptionMap } from '../../models/subscription-option-map.model';
import { StatusPipe } from 'src/app/pipes/status.pipe';

@Component({
  selector: 'app-subscription-options',
  templateUrl: './subscription-options.component.html',
  styleUrls: ['./subscription-options.component.scss'],
})
export class SubscriptionOptionsComponent {
  public ref: DynamicDialogRef = new DynamicDialogRef();
  public subscriptionOptions: SubscriptionOption[];
  public subscriptionsWithOptions: SubscriptionOptionMap[];

  constructor(
    public dataService: DataService,
    public notification: NotificationService,
    private primengConfig: PrimeNGConfig,
    public dialogService: DialogService
  ) {
    this.subscriptionOptions = [];
    this.subscriptionsWithOptions = [];
  }

  ngOnInit(): void {
    this.dataService
      .getAll<SubscriptionOption>(
        'api/admin/WebPortal/SubscriptionOptions/GetAll'
      )
      .subscribe((subscriptionOptions) => {
        this.subscriptionOptions = subscriptionOptions;
        this.notification.showSpinner.next(false);
      });

    // this.dataService
    //   .getAll<SubscriptionOptionMap>(
    //     'api/www/SubscriptionOptionMap/GetSubscriptionWithOptions'
    //   )
    //   .subscribe((subscriptionsWithOptions) => {
    //     this.subscriptionsWithOptions = subscriptionsWithOptions;
    //     this.notification.showSpinner.next(false);
    //   });
  }

  public showAddSubscriptionOption(): void {
    this.ref = this.dialogService.open(AddSubscriptionOptionComponent, {
      header: 'Add Subscription Options',
      width: '70%',
      baseZIndex: 10000,
      maximizable: false,
    });

    this.ref.onClose.subscribe((newSubscriptionOption) => {
      if (newSubscriptionOption != null) {
        this.dataService
          .add(
            'api/admin/WebPortal/SubscriptionOptions/Add',
            newSubscriptionOption
          )
          .subscribe(() => {
            this.notification.showSpinner.next(false);
            this.dataService
              .getAll<SubscriptionOption>(
                'api/admin/WebPortal/SubscriptionOptions/GetAll'
              )
              .subscribe((subscriptionOptions) => {
                this.subscriptionOptions = subscriptionOptions;
                this.notification.showSpinner.next(false);
              });
          });
      }
    });
  }

  public showEditSubscriptionOptions(
    subscriptionOption: SubscriptionOption
  ): void {
    this.ref = this.dialogService.open(EditSubscriptionOptionComponent, {
      data: {
        row: subscriptionOption,
      },
      header: 'Edit Subscription Options',
      width: '70%',
      baseZIndex: 10000,
      maximizable: false,
    });

    this.ref.onClose.subscribe((edited) => {
      if (edited != null) {
        this.dataService
          .update('api/admin/WebPortal/SubscriptionOptions/Edit', edited)
          .subscribe(() => {
            this.notification.showSpinner.next(false);
            this.dataService
              .getAll<SubscriptionOption>(
                'api/admin/WebPortal/SubscriptionOptions/GetAll'
              )
              .subscribe((subscriptionOptions) => {
                this.subscriptionOptions = subscriptionOptions;
                this.notification.showSpinner.next(false);
              });
          });
      }
    });
  }

  public swapEnableSubscriptionOptions(row: SubscriptionOption): void {
    row.isActive = row.isActive ? false : true;

    this.dataService
      .update('api/admin/WebPortal/SubscriptionOptions/Edit', row)
      .subscribe(() => {
        this.notification.showSpinner.next(false);
        this.dataService
          .getAll<SubscriptionOption>(
            'api/admin/WebPortal/SubscriptionOptions/GetAll'
          )
          .subscribe((subscriptionOptions) => {
            this.subscriptionOptions = subscriptionOptions;
            this.notification.showSpinner.next(false);
          });
      });
  }

  public showViewPlanMatrix(): void {
    this.ref = this.dialogService.open(ViewPlanMatrixComponent, {
      data: {
        subscriptionsWithOptions: this.subscriptionsWithOptions,
      },
      header: 'Subscription Plan Matrix',
      width: '70%',
      baseZIndex: 10000,
      maximizable: false,
    });
  }

  public showMappingOptions(): void {
    this.ref = this.dialogService.open(MappingOptionsComponent, {
      header: 'Subscription Plan Matrix',
      width: '70%',
      baseZIndex: 10000,
      maximizable: false,
    });
  }
}
