import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { UsermanagementComponent } from './main/usermanagement/usermanagement.component';
import { WebportalComponent } from './main/webportal/webportal.component';
import { KidsprogressComponent } from './main/kidsprogress/kidsprogress.component';
import { WellnessComponent } from './main/wellness/wellness.component';
import { AuthGuard } from '@auth0/auth0-angular';

const routes: Routes = [
  {
    path: 'usermanagement',
    component: UsermanagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'webportal',
    component: WebportalComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'kidsprogress',
    component: KidsprogressComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'wellness',
    component: WellnessComponent,
    canActivate: [AuthGuard],
  },
  { path: '', redirectTo: 'usermanagement', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
