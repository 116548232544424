import { Component } from '@angular/core';
import { SubscriptionOption } from '../../models/subscription-option.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-add-subscription-option',
  templateUrl: './add-subscription-option.component.html',
  styleUrls: ['./add-subscription-option.component.scss'],
})
export class AddSubscriptionOptionComponent {
  public newSubscriptionOption = new SubscriptionOption();
  public inputGroup: FormGroup;

  constructor(
    private primengConfig: PrimeNGConfig,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    this.inputGroup = new FormGroup({
      text: new FormControl('', [Validators.required]),
    });
  }

  public addSubscriptionOption(): void {
    this.newSubscriptionOption.isActive = true;
    this.newSubscriptionOption.text = this.inputGroup.get('text')?.value;
    this.ref.close(this.newSubscriptionOption);
  }

  public cancelAddSubscriptionOption(): void {
    this.ref.close();
  }
}
