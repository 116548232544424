<div>
  <form [formGroup]="kidsGroup" (submit)="editKid()">
    <div class="row">
      <div class="col-sm-2">
        <label for="firstName">First Name: </label>
      </div>
      <div class="col-sm-10">
        <input
          id="firstName"
          #firstName
          type="text"
          formControlName="firstName"
          pInputText
        />
        <small
          style="color: red"
          *ngIf="
            !kidsGroup.get('firstName')?.valid &&
            kidsGroup.get('firstName')?.dirty
          "
          >Please enter your first name.</small
        >
      </div>
    </div>

    <div class="clearfix visible-xs"></div>
    <div class="row">
      <div class="col-sm-2">
        <label for="lastName">Last Name: </label>
      </div>
      <div class="col-sm-10">
        <input
          id="lastName"
          #lastName
          type="text"
          formControlName="lastName"
          pInputText
        />
        <small
          style="color: red"
          *ngIf="
            !kidsGroup.get('lastName')?.valid &&
            kidsGroup.get('lastName')?.dirty
          "
          >Please enter your last name.</small
        >
      </div>
    </div>
    <div class="clearfix visible-xs"></div>
    <div class="row">
      <div class="col-sm-2">
        <label for="dateOfBirth">Date Of Birth: </label>
      </div>
      <div class="col-sm-10">
        <input
          id="dateOfBirth"
          #dateOfBirth
          type="date"
          formControlName="dateOfBirth"
          pInputText
        />
        <small
          style="color: red"
          *ngIf="
            !kidsGroup.get('dateOfBirth')?.valid &&
            kidsGroup.get('dateOfBirth')?.dirty
          "
          >Please enter a valid date.</small
        >
      </div>
    </div>

    <div class="clearfix visible-xs"></div>
    <div class="to-right flex">
      <p-button
        label="Cancel"
        styleClass="p-button-danger"
        (onClick)="cancelEdit()"
      ></p-button>
      <p-button
        label="Save"
        type="Submit"
        [disabled]="!kidsGroup.valid"
      ></p-button>
    </div>
  </form>
</div>
