<div class="flex">
  <form [formGroup]="messageGroup" (submit)="sendMessage()">
    <div class="col-sm-10">
      <div class="row">
        <label for="text">Input message here: </label>
      </div>

      <div class="clearfix visible-xs"></div>
      <div class="row">
        <div class="col-sm-2">
          <label for="subject">Subject: </label>
        </div>
        <div class="col-sm-10">
          <input
            id="subject"
            #subject
            required
            type="text"
            formControlName="subject"
            pInputText
            (keyup)="onSubjectKeyUp($any($event.target).value)"
          />
          <small
            style="color: red"
            *ngIf="
              !messageGroup.get('subject')?.valid &&
              messageGroup.get('subject')?.dirty
            "
            >Please enter a subject.</small
          >
        </div>
      </div>

      <div class="row">
        <div class="col-sm-2">
          <label for="subject">Message: </label>
        </div>
        <div class="col-sm-10">
          <textarea
            required
            rows="5"
            cols="50"
            pInputTextarea
            formControlName="message"
            [autoResize]="true"
            (keyup)="onMessageKeyUp($any($event.target).value)"
          ></textarea>
          <small
            style="color: red"
            *ngIf="
              !messageGroup.get('message')?.valid &&
              messageGroup.get('message')?.dirty
            "
            >Please enter a message.</small
          >
        </div>
      </div>
    </div>

    <div class="clearfix visible-xs"></div>
    <div class="to-right flex">
      <p-button
        label="Cancel"
        styleClass="p-button-danger"
        (onClick)="cancelSend()"
      ></p-button>
      <p-button
        label="Send"
        type="submit"
        [disabled]="!messageGroup.valid"
      ></p-button>
    </div>
  </form>
</div>
