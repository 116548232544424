import { EmailAddress } from './email-address.model';

export class EmailRecipient {
  public toRecipients!: EmailAddress[];
  public ccRecipients?: EmailAddress[];
  public bccRecipients?: EmailAddress[];

  constructor(
    toRecipients: EmailAddress[],
    ccRecipients?: EmailAddress[],
    bccRecipients?: EmailAddress[]
  ) {
    this.toRecipients = toRecipients;
    this.ccRecipients = ccRecipients;
    this.bccRecipients = bccRecipients;
  }
}
