import { Injectable, Inject } from '@angular/core';
import { UserProfile } from './model/user-profile.model';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public userProfile: UserProfile;
  public isAuthenticated: boolean;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public authService: AuthService
  ) {
    this.isAuthenticated = false;
    this.userProfile = new UserProfile();
    this.authService.isAuthenticated$.subscribe(
      (data) => (this.isAuthenticated = data)
    );

    this.authService.user$.subscribe((user) => {
      if (user?.['https://kidsgate.org/groups']) {
        this.userProfile.isEmployee = true;
      }
      this.userProfile.userId = user?.sub;
      this.userProfile.username = user?.preferred_username;
      this.userProfile.firstName = user?.given_name;
      this.userProfile.lastName = user?.family_name;
      this.userProfile.email = user?.email;
      const role: string = user?.['https://kidsgate.org/role'];
      this.userProfile.role = role;
      this.userProfile.initials = this.getInitials();
    });
  }
  public logout(): void {
    this.authService.logout({
      logoutParams: { returnTo: document.location.origin },
    });
  }
  private getInitials(): string {
    let initials: string = '';
    try {
      initials = `${this.userProfile.firstName?.substring(
        0,
        1
      )}${this.userProfile.lastName?.substring(0, 1)}`;
    } catch (error) {
      initials = 'UU';
    }
    return initials;
  }
}
