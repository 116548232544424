<form [formGroup]="inputGroup" (submit)="addType()">
  <div class="row mt-3">
    <div class="col-sm-2">
      <label for="type">Type:</label>
    </div>
    <div class="col-sm-10">
      <input id="type" type="text" pInputText formControlName="type" />
      <small
        style="color: red"
        *ngIf="
          !inputGroup.get('type')?.valid &&
          (inputGroup.get('type')?.dirty || inputGroup.get('type')?.touched)
        "
        >Please enter an Activity type.</small
      >
    </div>
  </div>
  <div class="clearfix visible-xs"></div>
  <div class="to-right flex mt-3">
    <p-button
      label="Cancel"
      styleClass="p-button-danger"
      (onClick)="cancelAddType()"
    ></p-button>
    <p-button
      label="Add Type"
      type="Submit"
      [disabled]="!inputGroup.valid"
    ></p-button>
  </div>
</form>
