import { Component } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DataService } from 'src/app/services/data.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-webportal',
  templateUrl: './webportal.component.html',
  styleUrls: ['./webportal.component.scss'],
  providers: [DialogService],
})
export class WebportalComponent {
  public ref: DynamicDialogRef = new DynamicDialogRef();

  constructor(
    public dataService: DataService,
    private primengConfig: PrimeNGConfig,
    public dialogService: DialogService,
    public notification: NotificationService
  ) {
    this.primengConfig.ripple = true;
  }
}
