import { Component } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DataService } from 'src/app/services/data.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Kid } from './models/kid.model';
import { WellnessProfessional } from './models/wellness-professional.model';
import { EditKidsWellnessProfessionalComponent } from './components/edit-kids-wellness-professional/edit-kids-wellness-professional.component';
import { ViewHistoryComponent } from './components/view-history/view-history.component';

class editHelper {
  public id?: number;
  public mentalHealthProId?: number;
  public dbRowVersion?: string;
}

@Component({
  selector: 'app-kidsprogress',
  templateUrl: './kidsprogress.component.html',
  styleUrls: ['./kidsprogress.component.scss'],
  providers: [DialogService],
})
export class KidsprogressComponent {
  public ref: DynamicDialogRef = new DynamicDialogRef();
  public kids: Kid[];
  public wellnessProfessionals: WellnessProfessional[];

  constructor(
    public dataService: DataService,
    private primengConfig: PrimeNGConfig,
    public dialogService: DialogService,
    public notification: NotificationService
  ) {
    this.kids = [];
    this.wellnessProfessionals = [];
  }

  ngOnInit(): void {
    this.dataService
      .getAll<Kid>('api/admin/KidsProgress/KidsProgress/GetAll')
      .subscribe((kids) => {
        this.kids = kids;
        this.notification.showSpinner.next(false);
      });

    this.dataService
      .getAll<WellnessProfessional>(
        'api/admin/KidsProgress/KidsProgress/GetAllWellnessProfessionals'
      )
      .subscribe((wellnessProfessionals) => {
        this.wellnessProfessionals = wellnessProfessionals;
        this.notification.showSpinner.next(false);
      });
  }

  public showEditKid(row: Kid): void {
    this.ref = this.dialogService.open(EditKidsWellnessProfessionalComponent, {
      data: {
        kid: row,
        wellnessProfessionals: this.wellnessProfessionals,
      },
      header: 'Edit Kid',
      width: '70%',
      baseZIndex: 10000,
      maximizable: false,
    });

    this.ref.onClose.subscribe((edited) => {
      if (edited != null) {
        let temp: editHelper = new editHelper();
        temp.id = row.id;
        temp.mentalHealthProId = edited;
        temp.dbRowVersion = row.dbRowVersion;

        this.dataService
          .update(
            'api/admin/KidsProgress/KidsProgress/UpdateKidsWellnessProfessional',
            temp
          )
          .subscribe(() => {
            this.notification.showSpinner.next(false);

            this.dataService
              .getAll<Kid>('api/admin/KidsProgress/KidsProgress/GetAll')
              .subscribe((kids) => {
                this.kids = kids;
                this.notification.showSpinner.next(false);
              });
          });
      }
    });
  }

  public showViewHistory(row: Kid): void {
    this.ref = this.dialogService.open(ViewHistoryComponent, {
      data: {
        kid: row,
      },
      header: 'View Child History',
      width: '70%',
      baseZIndex: 10000,
      maximizable: false,
    });
  }
}
