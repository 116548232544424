<div class="title">
  <h1 class="display-3">User Management</h1>
</div>
<div class="card">
  <p-accordion [multiple]="true" [activeIndex]="[0]">
    <p-accordionTab header="Kids">
      <p-table
        #dt2
        [value]="children"
        dataKey="id"
        responsiveLayout="scroll"
        styleClass="p-datatable-users p-datatable-striped"
        [globalFilterFields]="['name', 'parentName', 'userName', 'email']"
        [scrollable]="true"
        [(selection)]="selectedUsers"
        [showCurrentPageReport]="true"
        [rowHover]="true"
      >
        <ng-template pTemplate="caption">
          <div class="d-flex justify-content-between">
            <span class="to-left">
              <div style="font-size: 26px">Kids</div>
            </span>
            <span class="p-input-icon-right to-right">
              <!-- <p-button
            label="Add User"
            icon="pi pi-user"
            styleClass="p-button-success"
            iconPos="right"
            (onClick)="showAddUser()"
          ></p-button> -->
              <p-button
                label="Send Mass Notification"
                icon="pi pi-envelope"
                iconPos="right"
                (onClick)="showSendMassNotification()"
              ></p-button>
              <i class="pi pi-search to-right"></i>
              <input
                pInputText
                #searchInput
                type="text"
                (keyup.enter)="onInputChange(searchInput.value)"
                (keyup.backspace)="onClearSearch(searchInput.value)"
                placeholder="Search keyword"
              />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 4rem" id="checkbox">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th id="name">
              <div>Name</div>
            </th>
            <th id="parentName">
              <div>Parent</div>
            </th>
            <th id="email">
              <div>Email</div>
            </th>
            <th id="username">
              <div>Username</div>
            </th>
            <th id="gender">
              <div>Gender</div>
            </th>
            <th id="dateOfBirth">
              <div>DOB</div>
            </th>
            <th id="actions">
              <div>Actions</div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user>
          <tr>
            <td>
              <p-tableCheckbox [value]="user"></p-tableCheckbox>
            </td>
            <td class="long-text">
              <span>{{ user.firstName + " " + user.lastName }}</span>
            </td>
            <td>
              <span>{{ user.parentName }}</span>
            </td>
            <td>
              <span>{{ user.email }}</span>
            </td>
            <td>
              <span>{{ user.username }}</span>
            </td>
            <td>
              <span>{{ user.gender }}</span>
            </td>
            <td>
              <span>{{ user.dateOfBirth | date : "dd/MM/yyyy" }}</span>
            </td>
            <td>
              <p-button
                label="Edit"
                styleClass="p-button-warning p-button-sm"
                icon="pi pi-file-edit"
                iconPos="right"
                (onClick)="showEdit(user)"
              ></p-button>
              <!-- <p-button
            label="Delete"
            styleClass="p-button-danger p-button-sm"
            icon="pi pi-trash"
            iconPos="right"
            (onClick)="showDelete(user)"
          ></p-button> -->
              <p-button
                *ngIf="!user?.isDisabled"
                label="Disable"
                styleClass="p-button-danger p-button-sm"
                icon="pi pi-lock"
                iconPos="right"
                (onClick)="showSwapDisable(user)"
              ></p-button>
              <p-button
                *ngIf="user?.isDisabled"
                label="Enable"
                styleClass="p-button-primary p-button-sm"
                icon="pi pi-lock-open"
                iconPos="right"
                (onClick)="showSwapDisable(user)"
              ></p-button>
              <p-button
                label="Send Notification"
                styleClass="p-button-primary p-button-sm"
                icon="pi pi-envelope"
                iconPos="right"
                (onClick)="showSendNotification(user)"
              ></p-button>
              <!-- <p-button
            label="Login Activity"
            styleClass="p-button-primary p-button-sm"
            icon="pi pi-list"
            iconPos="right"
            (onClick)="showLoginActivity(user)"
          ></p-button> -->
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">No users found.</td>
          </tr>
        </ng-template>
      </p-table>
      <p-paginator
        (onPageChange)="handlePagination($event)"
        [rows]="userRequest.PageSize"
        [totalRecords]="userRequest.TotalCount || 0"
      ></p-paginator>
    </p-accordionTab>
    <p-accordionTab header="Parents" style="margin-top: 10px">
      <app-parents></app-parents>
    </p-accordionTab>
  </p-accordion>
</div>
