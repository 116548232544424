<div class="container-fluid main">
  <div class="nonFooterContent">
    <app-spinner></app-spinner>
    <header class="row">
      <app-navigation class="col"></app-navigation>
    </header>
    <main class="row">
      <router-outlet></router-outlet>
    </main>
  </div>
  <footer class="row">
    <app-footer class="col"> </app-footer>
  </footer>
</div>
