<p-table
  #dt2
  [value]="subscriptionOptions"
  dataKey="id"
  [rows]="10"
  styleClass="p-datatable-users p-datatable-striped"
  [globalFilterFields]="['text', 'status']"
  [scrollable]="true"
  scrollHeight="1000px"
  dataKey="couponCode"
  [tableStyle]="{ 'min-width': '50rem' }"
>
  <ng-template pTemplate="caption">
    <div class="flex">
      <span class="p-input-icon-right to-right">
        <p-button
          label="Add Subscription Option"
          icon="pi pi-user"
          styleClass="p-button-success"
          iconPos="right"
          (onClick)="showAddSubscriptionOption()"
        ></p-button>
        <p-button
          label="View Plan Matrix"
          icon="pi pi-user"
          styleClass="p-button"
          iconPos="right"
          (onClick)="showViewPlanMatrix()"
        ></p-button>
        <p-button
          label="Mapping Options"
          icon="pi pi-user"
          styleClass="p-button"
          iconPos="right"
          (onClick)="showMappingOptions()"
        ></p-button>
        <i class="pi pi-search to-right"></i>
        <input
          pInputText
          type="text"
          (input)="dt2.filterGlobal($any($event.target).value, 'contains')"
          placeholder="Search keyword"
        />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th id="optionText" class="table-col-width-small">
        <div class="p-d-flex p-jc-between p-ai-center">Option Text</div>
      </th>
      <th id="optionText" class="table-col-width-small">
        <div class="p-d-flex p-jc-between p-ai-center">Status</div>
      </th>
      <th id="actions" class="table-col-width-large">
        <div class="p-d-flex p-jc-between p-ai-center">Actions</div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-subscriptionOption>
    <tr>
      <td>
        <span>{{ subscriptionOption.text }}</span>
      </td>
      <td>
        <span>{{ subscriptionOption.isActive | status }}</span>
      </td>
      <td>
        <p-button
          label="Edit"
          styleClass="p-button-warning p-button-sm"
          icon="pi pi-file-edit"
          iconPos="right"
          (onClick)="showEditSubscriptionOptions(subscriptionOption)"
        ></p-button>
        <p-button
          *ngIf="subscriptionOption.isActive"
          label="Disable"
          styleClass="p-button-danger p-button-sm"
          icon="pi pi-lock"
          iconPos="right"
          (onClick)="swapEnableSubscriptionOptions(subscriptionOption)"
        ></p-button>
        <p-button
          *ngIf="!subscriptionOption.isActive"
          label="Enable"
          styleClass="p-button-primary p-button-sm"
          icon="pi pi-lock-open"
          iconPos="right"
          (onClick)="swapEnableSubscriptionOptions(subscriptionOption)"
        ></p-button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7">No coupons found.</td>
    </tr>
  </ng-template>
</p-table>
