<form [formGroup]="wellnessGroup" (submit)="addWellnessUser()">
  <div class="row">
    <div class="col-sm-2">
      <label for="firstName">First Name: </label>
    </div>
    <div class="col-sm-10">
      <input
        id="firstName"
        #firstName
        required
        type="text"
        formControlName="firstName"
        pInputText
        (keyup)="onWellnessFirstNameKeyUp($any($event.target).value)"
      />
      <small
        style="color: red"
        *ngIf="
          !wellnessGroup.get('firstName')?.valid &&
          (wellnessGroup.get('firstName')?.dirty ||
            wellnessGroup.get('firstName')?.touched)
        "
        >Please enter your first name.</small
      >
    </div>
  </div>

  <div class="clearfix visible-xs"></div>
  <div class="row">
    <div class="col-sm-2">
      <label for="lastName">Last Name: </label>
    </div>
    <div class="col-sm-10">
      <input
        id="lastName"
        #lastName
        required
        type="text"
        formControlName="lastName"
        pInputText
        (keyup)="onWellnessLastNameKeyUp($any($event.target).value)"
      />
      <small
        style="color: red"
        *ngIf="
          !wellnessGroup.get('lastName')?.valid &&
          (wellnessGroup.get('lastName')?.dirty ||
            wellnessGroup.get('lastName')?.touched)
        "
        >Please enter your last name.</small
      >
    </div>
  </div>

  <div class="row">
    <div class="col-sm-2">
      <label for="country">Country: </label>
    </div>
    <div class="col-sm-10">
      <p-dropdown
        [options]="countries"
        [(ngModel)]="selectedCountry"
        optionLabel="fullName"
        [filter]="true"
        filterBy="fullName"
        [showClear]="true"
        placeholder="Select a Country"
        formControlName="country"
        [required]="true"
        [style]="{ width: '100%' }"
      >
        <ng-template pTemplate="selectedItem">
          <div class="flex align-items-center gap-2" *ngIf="selectedCountry">
            <div>{{ selectedCountry.fullName }}</div>
          </div>
        </ng-template>
        <ng-template let-countries pTemplate="item">
          <div class="flex align-items-center gap-2">
            <div>{{ countries.fullName }}</div>
          </div>
        </ng-template>
      </p-dropdown>
      <small
        style="color: red"
        *ngIf="
          !wellnessGroup.get('country')?.valid &&
          (wellnessGroup.get('country')?.dirty ||
            wellnessGroup.get('country')?.touched)
        "
        >Please select a Country.</small
      >
    </div>
  </div>

  <div class="clearfix visible-xs"></div>
  <div class="row">
    <div class="col-sm-2">
      <label for="username">Username: </label>
    </div>
    <div class="col-sm-10">
      <input
        id="username"
        #username
        required
        type="text"
        formControlName="username"
        pInputText
        (keyup)="onWellnessUsernameKeyUp($any($event.target).value)"
      />
      <small
        style="color: red"
        *ngIf="
          !wellnessGroup.get('username')?.valid &&
          (wellnessGroup.get('username')?.dirty ||
            wellnessGroup.get('username')?.touched)
        "
        >Please enter a username (it must be at least 5 characters).</small
      >
    </div>
  </div>

  <div class="clearfix visible-xs"></div>
  <div class="row">
    <div class="col-sm-2">
      <label for="password">Password: </label>
    </div>
    <div class="col-sm-10">
      <input
        id="password"
        #password
        required
        type="password"
        formControlName="password"
        pInputText
        (keyup)="onWellnessPasswordKeyUp($any($event.target).value)"
      />
      <small
        style="color: red"
        *ngIf="
          !wellnessGroup.get('password')?.valid &&
          (wellnessGroup.get('password')?.dirty ||
            wellnessGroup.get('password')?.touched)
        "
        >Please enter your password. It needs to have at least one lower case
        letter, one upper case letter, one number, and one special
        character.</small
      >
    </div>
  </div>

  <div class="clearfix visible-xs"></div>
  <div class="row">
    <div class="col-sm-2">
      <label for="confirmPassword">Confirm Password: </label>
    </div>
    <div class="col-sm-10">
      <input
        id="confirmPassword"
        #confirmPassword
        type="password"
        formControlName="confirmPassword"
        pInputText
        (keyup)="onWellnessConfirmPasswordKeyUp($any($event.target).value)"
      />
      <small
        style="color: red"
        *ngIf="
          !wellnessGroup.get('confirmPassword')?.valid &&
          (wellnessGroup.get('confirmPassword')?.dirty ||
            wellnessGroup.get('confirmPassword')?.touched)
        "
        >This does not match your previous password.</small
      >
    </div>
  </div>

  <div class="clearfix visible-xs"></div>
  <div class="row">
    <div class="col-sm-2">
      <label for="email">Email: </label>
    </div>
    <div class="col-sm-10">
      <input
        id="email"
        #email
        required
        type="text"
        formControlName="email"
        pInputText
        (keyup)="onWellnessEmailKeyUp($any($event.target).value)"
      />
      <small
        style="color: red"
        *ngIf="
          !wellnessGroup.get('email')?.valid &&
          (wellnessGroup.get('email')?.dirty ||
            wellnessGroup.get('email')?.touched)
        "
        >Please enter a valid email address.</small
      >
    </div>
  </div>

  <div class="clearfix visible-xs"></div>
  <div class="to-right flex">
    <p-button
      label="Cancel"
      styleClass="p-button-danger"
      (onClick)="cancelAddUser()"
    ></p-button>
    <p-button
      label="Add User"
      type="Submit"
      [disabled]="!wellnessGroup.valid"
    ></p-button>
  </div>
</form>
