<form [formGroup]="inputGroup" (submit)="editInspirationalMessage()">
  <div class="row">
    <div class="col-sm-2">
      <label for="isActive">Active:</label>
    </div>
    <div class="col-sm-10">
      <p-dropdown
        formControlName="isActive"
        [options]="activeOptions"
        optionLabel="name"
      ></p-dropdown>
      <small
        style="color: red"
        *ngIf="
          !inputGroup.get('isActive')?.valid &&
          inputGroup.get('isActive')?.dirty
        "
        >Please enter if this message is active or not.</small
      >
    </div>
  </div>

  <div class="clearfix visible-xs"></div>
  <div class="row">
    <div class="col-sm-2">
      <label for="message">Message:</label>
    </div>
    <div class="col-sm-10">
      <textarea
        id="message"
        rows="5"
        cols="30"
        pInputTextarea
        formControlName="message"
      ></textarea>
      <small
        style="color: red"
        *ngIf="
          !inputGroup.get('message')?.valid && inputGroup.get('message')?.dirty
        "
        >Please enter an inspirational message.</small
      >
    </div>
  </div>

  <div class="clearfix visible-xs"></div>
  <div class="to-right flex">
    <p-button
      label="Cancel"
      styleClass="p-button-danger"
      (onClick)="cancelEditMessage()"
    ></p-button>
    <p-button
      label="Edit Inspirational Message"
      type="Submit"
      [disabled]="!inputGroup.valid"
    ></p-button>
  </div>
</form>
