import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ParentDto } from '../../model/userDto.model';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DataService } from 'src/app/services/data.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-edit-parent-user',
  templateUrl: './edit-parent-user.component.html',
  styleUrls: ['./edit-parent-user.component.scss'],
})
export class EditParentUserComponent implements OnInit {
  public ParentGroup!: FormGroup;
  public user: ParentDto;

  constructor(
    public dataService: DataService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public notification: NotificationService,
    private fb: FormBuilder
  ) {
    this.user = this.config.data.user;

    if (this.user.auth0UserId !== null) {
      this.ParentGroup = this.fb.group({
        firstName: [this.user.firstName, [Validators.required]],
        lastName: [this.user.lastName, [Validators.required]],
        address1: [this.user.address1, [Validators.required]],
        address2: [this.user.address2],
        city: [this.user.city],
      });
    }
  }

  ngOnInit(): void {}

  public editParent(): void {
    if (this.ParentGroup.valid) {
      this.user.firstName = this.ParentGroup.get('firstName')?.value;
      this.user.lastName = this.ParentGroup.get('lastName')?.value;
      this.user.address1 = this.ParentGroup.get('address1')?.value;
      this.user.address2 = this.ParentGroup.get('address2')?.value;
      this.user.city = this.ParentGroup.get('city')?.value;

      this.dataService
        .update(
          'api/admin/users/UserManagement/EditParentPortalUser',
          this.user
        )
        .subscribe((response) => {
          this.notification.showSpinner.next(false);
        });

      this.ref.close({ user: this.user });
    }
  }

  public cancelEdit(): void {
    this.ref.close({ user: null });
  }
}
