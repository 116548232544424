import { Component } from '@angular/core';
import { SubscriptionPlan } from '../../models/subscription-plan.model';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DataService } from 'src/app/services/data.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PrimeNGConfig } from 'primeng/api';
import { AddSubscriptionPlanComponent } from '../add-subscription-plan/add-subscription-plan.component';
import { EditSubscriptionPlanComponent } from '../edit-subscription-plan/edit-subscription-plan.component';
import { DeleteSubscriptionPlanComponent } from '../delete-subscription-plan/delete-subscription-plan.component';
import { StatusPipe } from 'src/app/pipes/status.pipe';

@Component({
  selector: 'app-subscription-plans',
  templateUrl: './subscription-plans.component.html',
  styleUrls: ['./subscription-plans.component.scss'],
})
export class SubscriptionPlansComponent {
  public ref: DynamicDialogRef = new DynamicDialogRef();
  public subscriptionPlans: SubscriptionPlan[];

  constructor(
    public dataService: DataService,
    public notification: NotificationService,
    private primengConfig: PrimeNGConfig,
    public dialogService: DialogService
  ) {
    this.subscriptionPlans = [];
  }

  ngOnInit(): void {
    this.dataService
      .getAll<SubscriptionPlan>('api/admin/WebPortal/SubscriptionPlans/GetAll')
      .subscribe((subscriptions) => {
        this.subscriptionPlans = subscriptions;
        this.notification.showSpinner.next(false);
      });
  }

  public showAddSubscriptionPlan(): void {
    this.ref = this.dialogService.open(AddSubscriptionPlanComponent, {
      header: 'Add Subscription Plan',
      width: '70%',
      baseZIndex: 10000,
      maximizable: false,
    });

    this.ref.onClose.subscribe((newSubscriptionPlan) => {
      if (newSubscriptionPlan != null) {
        this.dataService
          .add('api/admin/WebPortal/SubscriptionPlans/Add', newSubscriptionPlan)
          .subscribe(() => {
            this.notification.showSpinner.next(false);
            this.dataService
              .getAll<SubscriptionPlan>(
                'api/admin/WebPortal/SubscriptionPlans/GetAll'
              )
              .subscribe((subscriptions) => {
                this.subscriptionPlans = subscriptions;
                this.notification.showSpinner.next(false);
              });
          });
      }
    });
  }

  public showEditSubscriptionPlan(subscriptionPlan: SubscriptionPlan): void {
    this.ref = this.dialogService.open(EditSubscriptionPlanComponent, {
      data: {
        row: subscriptionPlan,
      },
      header: 'Edit Subscription Plan',
      width: '70%',
      baseZIndex: 10000,
      maximizable: false,
    });

    this.ref.onClose.subscribe((edited) => {
      if (edited != null) {
        this.dataService
          .update('api/admin/WebPortal/SubscriptionPlans/Edit', edited)
          .subscribe(() => {
            this.notification.showSpinner.next(false);
            this.dataService
              .getAll<SubscriptionPlan>(
                'api/admin/WebPortal/SubscriptionPlans/GetAll'
              )
              .subscribe((subscriptionPlan) => {
                this.subscriptionPlans = subscriptionPlan;
                this.notification.showSpinner.next(false);
              });
          });
      }
    });
  }

  public swapEnableSubscriptionPlan(row: SubscriptionPlan): void {
    row.isActive = row.isActive ? false : true;

    this.dataService
      .update('api/admin/WebPortal/SubscriptionPlans/Edit', row)
      .subscribe(() => {
        this.notification.showSpinner.next(false);
        this.dataService
          .getAll<SubscriptionPlan>(
            'api/admin/WebPortal/SubscriptionPlans/GetAll'
          )
          .subscribe((subscriptionPlan) => {
            this.subscriptionPlans = subscriptionPlan;
            this.notification.showSpinner.next(false);
          });
      });
  }

  public showDeleteSubscriptionPlan(row: SubscriptionPlan): void {
    this.ref = this.dialogService.open(DeleteSubscriptionPlanComponent, {
      header: 'Delete Subscription Plan',
      width: '70%',
      baseZIndex: 10000,
      maximizable: false,
    });

    this.ref.onClose.subscribe((ifDelete) => {
      if (ifDelete) {
        this.dataService
          .deleteById(
            'api/admin/WebPortal/SubscriptionPlans/Delete',
            row.id as number
          )
          .subscribe(() => {
            this.notification.showSpinner.next(false);

            this.dataService
              .getAll<SubscriptionPlan>(
                'api/admin/WebPortal/SubscriptionPlans/GetAll'
              )
              .subscribe((subscriptionPlan) => {
                this.subscriptionPlans = subscriptionPlan;
                this.notification.showSpinner.next(false);
              });
          });
      }
    });
  }
}
