import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { WellnessProfessional } from 'src/app/main/kidsprogress/models/wellness-professional.model';

class FullWellnessProfessional {
  id?: number;
  firstName?: string;
  lastName?: string;
  fullName?: string;
}

@Component({
  selector: 'app-edit-kids-wellness-professional',
  templateUrl: './edit-kids-wellness-professional.component.html',
  styleUrls: ['./edit-kids-wellness-professional.component.scss'],
})
export class EditKidsWellnessProfessionalComponent {
  public inputGroup: FormGroup;
  public wellnessProfessional: WellnessProfessional;
  public wellnessProfessionalList: WellnessProfessional[];
  public fullWellnessProfessionalList: FullWellnessProfessional[];

  constructor(
    private primengConfig: PrimeNGConfig,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    this.wellnessProfessional = this.config.data.row;
    this.wellnessProfessionalList = this.config.data.wellnessProfessionals;
    this.fullWellnessProfessionalList = [];

    //Populates a list of wellnessProfessionals with "fullName" so that it can
    //be displayed in the edit dropdown.
    this.wellnessProfessionalList.forEach((wellnessProfessional) => {
      let temp: FullWellnessProfessional = new FullWellnessProfessional();
      temp.id = wellnessProfessional.id;
      temp.firstName = wellnessProfessional.firstName;
      temp.lastName = wellnessProfessional.lastName;
      temp.fullName = temp.firstName + ' ' + temp.lastName;
      this.fullWellnessProfessionalList.push(temp);
    });
    this.inputGroup = new FormGroup({
      wellnessProfessional: new FormControl('', [Validators.required]),
    });
  }

  public editKidsWellnessProfessional(): void {
    this.ref.close(this.inputGroup.get('wellnessProfessional')?.value.id);
  }

  public cancelEdit(): void {
    this.ref.close();
  }
}
