<p-table
  #dt2
  [value]="parents"
  dataKey="id"
  responsiveLayout="scroll"
  styleClass="p-datatable-users p-datatable-striped"
  [scrollable]="true"
  [showCurrentPageReport]="true"
  [rowHover]="true"
>
  <ng-template pTemplate="caption">
    <div class="d-flex justify-content-between">
      <span class="to-left">
        <div style="font-size: 26px">Parents</div>
      </span>
      <span class="p-input-icon-right to-right">
        <p-button
          label="Add User"
          icon="pi pi-user"
          styleClass="p-button-success"
          iconPos="right"
          (onClick)="showAddUser()"
        ></p-button>
        <p-button
          label="Send Mass Notification"
          icon="pi pi-envelope"
          iconPos="right"
          (onClick)="showSendMassNotification()"
        ></p-button>
        <i class="pi pi-search to-right"></i>
        <input
          pInputText
          #searchInput
          type="text"
          (keyup.enter)="onInputChange(searchInput.value)"
          (keyup.backspace)="onClearSearch(searchInput.value)"
          placeholder="Search keyword"
        />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 4rem" id="checkbox">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th id="name">
        <div>Name</div>
      </th>
      <th id="email">
        <div>Email</div>
      </th>
      <th id="username">
        <div>Username</div>
      </th>

      <th id="actions">
        <div style="text-align: center">Actions</div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-user>
    <tr>
      <td>
        <p-tableCheckbox [value]="user"></p-tableCheckbox>
      </td>
      <td>
        <span>{{ user.firstName + " " + user.lastName }}</span>
      </td>
      <td>
        <span>{{ user.email }}</span>
      </td>
      <td>
        <span>{{ user.username }}</span>
      </td>

      <td>
        <p-button
          label="Edit"
          styleClass="p-button-warning p-button-sm"
          icon="pi pi-file-edit"
          iconPos="right"
          (onClick)="showEdit(user)"
        ></p-button>
        <p-button
          *ngIf="!user?.isDisabled"
          label="Disable"
          styleClass="p-button-danger p-button-sm"
          icon="pi pi-lock"
          iconPos="right"
          (onClick)="showSwapDisable(user)"
        ></p-button>
        <p-button
          *ngIf="user?.isDisabled"
          label="Enable"
          styleClass="p-button-primary p-button-sm"
          icon="pi pi-lock-open"
          iconPos="right"
          (onClick)="showSwapDisable(user)"
        ></p-button>
        <p-button
          label="Send Notification"
          styleClass="p-button-primary p-button-sm"
          icon="pi pi-envelope"
          iconPos="right"
          (onClick)="showSendNotification(user)"
        ></p-button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7">No users found.</td>
    </tr>
  </ng-template>
</p-table>
<p-paginator
  (onPageChange)="handlePagination($event)"
  [rows]="userRequest.PageSize"
  [totalRecords]="userRequest.TotalCount || 0"
></p-paginator>
