import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AddParentUser } from '../../model/addParentUser.model';
import { AddWellnessUser } from '../../model/addWellnessUser.model';
import { Country } from '../../model/country.model';
import { DataService } from 'src/app/services/data.service';
import { NotificationService } from 'src/app/services/notification.service';
import { User } from '../../model/user.model';

interface PortalName {
  name: string;
}

@Component({
  selector: 'app-add-user',
  templateUrl: './addUser.component.html',
  styleUrls: ['./addUser.component.scss'],
})
export class AddUserComponent implements OnInit {
  public portalNames!: PortalName[];
  public parentGroup!: FormGroup;
  public wellnessGroup!: FormGroup;
  public selectedPortalName!: PortalName;
  public selectedPortalNameString!: string;
  public newParentUser: AddParentUser;
  public newWellnessUser: AddWellnessUser;
  public countries: Country[];
  public selectedCountry: Country;
  public usedUsername: boolean = false;
  constructor(
    public dataService: DataService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public notification: NotificationService
  ) {
    this.newParentUser = new AddParentUser();
    this.newWellnessUser = new AddWellnessUser();
    this.countries = this.config.data.countries;
    this.selectedCountry = new Country();
  }

  ngOnInit(): void {
    this.portalNames = [{ name: 'Parent' }, { name: 'Wellness' }];
    this.changeDropdown(this.portalNames[0]);

    this.parentGroup = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      username: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(
          '(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])(?=[^!@#$%^&*()?]*[!@#$%^&*()?]).{8,30}'
        ),
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        this.matchValidator('password'),
      ]),
      email: new FormControl('', [Validators.required, Validators.email]),
      address1: new FormControl('', [Validators.required]),
      address2: new FormControl(''),
      address3: new FormControl(''),
      address4: new FormControl(''),
      postalCode: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
    });

    this.wellnessGroup = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      username: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(
          '(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])(?=[^!@#$%^&*()?]*[!@#$%^&*()?]).{8,30}'
        ),
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        this.matchValidator('password'),
      ]),
      email: new FormControl('', [Validators.required, Validators.email]),
      country: new FormControl('', [Validators.required]),
    });
  }

  //Used to match password with confirm password text inputs
  public matchValidator(matchTo: string, reverse?: boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.parent && reverse) {
        const c = (control.parent?.controls as any)[matchTo];
        if (c) {
          c.updateValueAndValidity();
        }
        return null;
      }
      return !!control.parent &&
        !!control.parent.value &&
        control.value === (control.parent?.controls as any)[matchTo].value
        ? null
        : { matching: true };
    };
  }

  public addParentUser(): void {
    let newUser: User = new User();

    this.newParentUser.portalName = 'Parents';
    this.newParentUser.firstName = this.parentGroup.get('firstName')?.value;
    this.newParentUser.lastName = this.parentGroup.get('lastName')?.value;
    this.newParentUser.username = this.parentGroup.get('username')?.value;
    this.newParentUser.email = this.parentGroup.get('email')?.value;
    this.newParentUser.password = this.parentGroup.get('password')?.value;
    this.newParentUser.address1 = this.parentGroup.get('address1')?.value;
    this.newParentUser.address2 = this.parentGroup.get('address2')?.value;
    this.newParentUser.address3 = this.parentGroup.get('address3')?.value;
    this.newParentUser.address4 = this.parentGroup.get('address4')?.value;
    this.newParentUser.city = this.parentGroup.get('city')?.value;
    this.newParentUser.postalCode = this.parentGroup.get('postalCode')?.value;
    this.newParentUser.countryId = this.parentGroup.get('country')?.value.id;

    this.dataService
      .add(
        'api/admin/users/UserManagement/AddParentPortalUser',
        this.newParentUser
      )
      .subscribe((res: any) => {
        this.notification.showSpinner.next(false);

        if (res && res.isSuccessful == true) {
          newUser.portal = 'Parents';
          newUser.firstName = this.newParentUser.firstName;
          newUser.lastName = this.newParentUser.lastName;
          newUser.username = this.newParentUser.username;
          newUser.email = this.newParentUser.email;
          newUser.address1 = this.newParentUser.address1;
          newUser.address2 = this.newParentUser.address2;
          newUser.address3 = this.newParentUser.address3;
          newUser.address4 = this.newParentUser.address4;
          newUser.city = this.newParentUser.city;
          newUser.postalCode = this.newParentUser.postalCode;
          newUser.countryId = this.newParentUser.countryId;
          this.ref.close(newUser);
          this.notification.showSuccessMessage('User Added Successfully', '');
        } else {
          let error = JSON.parse(res.errorMessage);
          this.notification.showErrorMessage(error.message, 'Error');
        }
      });
  }

  public addWellnessUser(): void {
    let newUser: User = new User();

    this.newWellnessUser.portalName = 'Wellness';
    this.newWellnessUser.firstName = this.wellnessGroup.get('firstName')?.value;
    this.newWellnessUser.lastName = this.wellnessGroup.get('lastName')?.value;
    this.newWellnessUser.username = this.wellnessGroup.get('username')?.value;
    this.newWellnessUser.email = this.wellnessGroup.get('email')?.value;
    this.newWellnessUser.password = this.wellnessGroup.get('password')?.value;
    this.newWellnessUser.countryId =
      this.wellnessGroup.get('country')?.value.id;

    this.dataService
      .add(
        'api/admin/users/UserManagement/AddWellnessPortalUser',
        this.newWellnessUser
      )
      .subscribe((response) => {
        this.notification.showSpinner.next(false);
      });

    newUser.portal = 'Wellness';
    newUser.firstName = this.newWellnessUser.firstName;
    newUser.lastName = this.newWellnessUser.lastName;
    newUser.username = this.newWellnessUser.username;
    newUser.email = this.newWellnessUser.email;
    newUser.countryId = this.newWellnessUser.countryId;
    this.ref.close(newUser);
  }

  public changeDropdown(selectedPortalName: PortalName): void {
    this.selectedPortalNameString = selectedPortalName.name;
    this.config.header =
      'Create a ' + selectedPortalName.name.toLowerCase() + ' user';
  }

  public cancelAddUser(): void {
    this.ref.close();
  }

  public onParentFirstNameKeyUp(args: any): void {
    this.parentGroup.get('firstName')?.setValue(args);
  }

  public onParentLastNameKeyUp(args: any): void {
    this.parentGroup.get('lastName')?.setValue(args);
  }

  public onParentUsernameKeyUp(args: any): void {
    this.parentGroup.get('username')?.setValue(args);
  }

  public onParentPasswordKeyUp(args: any): void {
    this.parentGroup.get('password')?.setValue(args);
  }

  public onParentConfirmPasswordKeyUp(args: any): void {
    this.parentGroup.get('confirmPassword')?.setValue(args);
  }

  public onParentEmailKeyUp(args: any): void {
    this.parentGroup.get('email')?.setValue(args);
  }

  public onParentAddress1KeyUp(args: any): void {
    this.parentGroup.get('address1')?.setValue(args);
  }

  public onParentAddress2KeyUp(args: any): void {
    this.parentGroup.get('address2')?.setValue(args);
  }

  public onParentAddress3KeyUp(args: any): void {
    this.parentGroup.get('address3')?.setValue(args);
  }

  public onParentAddress4KeyUp(args: any): void {
    this.parentGroup.get('address4')?.setValue(args);
  }

  public onParentCityKeyUp(args: any): void {
    this.parentGroup.get('city')?.setValue(args);
  }

  public onParentPostalCodeKeyUp(args: any): void {
    this.parentGroup.get('postalCode')?.setValue(args);
  }

  public onWellnessFirstNameKeyUp(args: any): void {
    this.wellnessGroup.get('firstName')?.setValue(args);
  }

  public onWellnessLastNameKeyUp(args: any): void {
    this.wellnessGroup.get('lastName')?.setValue(args);
  }

  public onWellnessUsernameKeyUp(args: any): void {
    this.wellnessGroup.get('username')?.setValue(args);
    this.usedUsername = false;
  }

  public onWellnessPasswordKeyUp(args: any): void {
    this.wellnessGroup.get('password')?.setValue(args);
  }

  public onWellnessConfirmPasswordKeyUp(args: any): void {
    this.wellnessGroup.get('confirmPassword')?.setValue(args);
  }

  public onWellnessEmailKeyUp(args: any): void {
    this.wellnessGroup.get('email')?.setValue(args);
  }
}
