<div class="title">
  <h1 class="display-3">Wellness Professionals</h1>
</div>
<div class="card">
  <p-table
    #dt2
    [value]="users"
    dataKey="id"
    [rows]="10"
    styleClass="p-datatable-users p-datatable-striped"
    [globalFilterFields]="['firstName', 'lastName', 'userName']"
    [scrollable]="true"
    scrollHeight="1000px"
    dataKey="firstName"
    [tableStyle]="{ 'min-width': '50rem' }"
  >
    <ng-template pTemplate="caption">
      <div class="flex">
        <span class="p-input-icon-right to-right">
          <p-button
            label="Add Wellness Professional"
            icon="pi pi-user"
            styleClass="p-button-success"
            iconPos="right"
            (onClick)="showAddUser()"
          ></p-button>
          <i class="pi pi-search to-right"></i>
          <input
            pInputText
            type="text"
            (input)="dt2.filterGlobal($any($event.target).value, 'contains')"
            placeholder="Search keyword"
          />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th id="firstName" class="table-col-width-small">
          <div class="p-d-flex p-jc-between p-ai-center">First Name</div>
        </th>
        <th id="lastName" class="table-col-width-small">
          <div class="p-d-flex p-jc-between p-ai-center">Last Name</div>
        </th>
        <th id="username" class="table-col-width-small">
          <div class="p-d-flex p-jc-between p-ai-center">Username</div>
        </th>
        <th id="actions" class="table-col-width-small">
          <div class="p-d-flex p-jc-between p-ai-center">Actions</div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user>
      <tr>
        <td>
          <span>{{ user.firstName }}</span>
        </td>
        <td>
          <span>{{ user.lastName }}</span>
        </td>
        <td>
          <span>{{ user.username }}</span>
        </td>
        <td>
          <p-button
            *ngIf="user.isActive"
            label="Disable"
            styleClass="p-button-danger p-button-sm"
            icon="pi pi-lock"
            iconPos="right"
            (onClick)="swapEnableUser(user)"
          ></p-button>
          <p-button
            *ngIf="!user.isActive"
            label="Enable"
            styleClass="p-button-primary p-button-sm"
            icon="pi pi-lock-open"
            iconPos="right"
            (onClick)="swapEnableUser(user)"
          ></p-button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7">No mental health professionals found.</td>
      </tr>
    </ng-template>
  </p-table>
</div>
