import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { AddWellnessUser } from '../../model/addWellnessUser.model';
import { Country } from '../../model/country.model';
import { DataService } from 'src/app/services/data.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NotificationService } from 'src/app/services/notification.service';
import { User } from '../../model/user.model';

@Component({
  selector: 'app-add-wellness-professional',
  templateUrl: './add-wellness-professional.component.html',
  styleUrls: ['./add-wellness-professional.component.scss'],
})
export class AddWellnessProfessionalComponent implements OnInit {
  public wellnessGroup!: FormGroup;
  public newWellnessUser: AddWellnessUser;
  public countries: Country[];
  public selectedCountry: Country;

  constructor(
    public dataService: DataService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public notification: NotificationService
  ) {
    this.newWellnessUser = new AddWellnessUser();
    this.countries = this.config.data.countries;
    this.selectedCountry = new Country();
  }

  ngOnInit(): void {
    this.wellnessGroup = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      username: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(
          '(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])(?=[^!@#$%^&*()?]*[!@#$%^&*()?]).{8,30}'
        ),
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        this.matchValidator('password'),
      ]),
      email: new FormControl('', [Validators.required, Validators.email]),
      country: new FormControl('', [Validators.required]),
    });
  }

  //Used to match password with confirm password text inputs
  public matchValidator(matchTo: string, reverse?: boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.parent && reverse) {
        const c = (control.parent?.controls as any)[matchTo];
        if (c) {
          c.updateValueAndValidity();
        }
        return null;
      }
      return !!control.parent &&
        !!control.parent.value &&
        control.value === (control.parent?.controls as any)[matchTo].value
        ? null
        : { matching: true };
    };
  }

  public addWellnessUser(): void {
    let newUser: User = new User();

    this.newWellnessUser.portalName = 'Wellness';
    this.newWellnessUser.firstName = this.wellnessGroup.get('firstName')?.value;
    this.newWellnessUser.lastName = this.wellnessGroup.get('lastName')?.value;
    this.newWellnessUser.username = this.wellnessGroup.get('username')?.value;
    this.newWellnessUser.email = this.wellnessGroup.get('email')?.value;
    this.newWellnessUser.password = this.wellnessGroup.get('password')?.value;
    this.newWellnessUser.countryId =
      this.wellnessGroup.get('country')?.value.id;
    this.ref.close(this.newWellnessUser);
  }

  public cancelAddUser(): void {
    this.ref.close();
  }

  public onWellnessFirstNameKeyUp(args: any): void {
    this.wellnessGroup.get('firstName')?.setValue(args);
  }

  public onWellnessLastNameKeyUp(args: any): void {
    this.wellnessGroup.get('lastName')?.setValue(args);
  }

  public onWellnessUsernameKeyUp(args: any): void {
    this.wellnessGroup.get('username')?.setValue(args);
  }

  public onWellnessPasswordKeyUp(args: any): void {
    this.wellnessGroup.get('password')?.setValue(args);
  }

  public onWellnessConfirmPasswordKeyUp(args: any): void {
    this.wellnessGroup.get('confirmPassword')?.setValue(args);
  }

  public onWellnessEmailKeyUp(args: any): void {
    this.wellnessGroup.get('email')?.setValue(args);
  }
}
