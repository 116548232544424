<p-table
  #dt2
  [value]="activities"
  dataKey="id"
  [rows]="10"
  styleClass="p-datatable-users p-datatable-striped"
  [globalFilterFields]="[
    'name',
    'activityTypeDescription',
    'activityScheduleDescripiton',
    'dayOfWeek',
    'dayOfMonth',
    'stars',
    'isActive'
  ]"
  [scrollable]="true"
  scrollHeight="1000px"
  dataKey="message"
  [tableStyle]="{ 'min-width': '50rem' }"
>
  <ng-template pTemplate="caption">
    <div class="flex">
      <span class="p-input-icon-right to-right">
        <p-button
          label="Assign Activities"
          styleClass="p-button-primary"
          (onClick)="assignActivities()"
        ></p-button>
        <p-button
          label="Add Activity Type"
          styleClass="p-button-warning"
          (onClick)="showAddActivityTypeLookup()"
        ></p-button>
        <p-button
          label="Add Activity"
          styleClass="p-button-success"
          (onClick)="showAddActivity()"
        ></p-button>
        <i class="pi pi-search to-right"></i>
        <input
          pInputText
          type="text"
          (input)="dt2.filterGlobal($any($event.target).value, 'contains')"
          placeholder="Search keyword"
        />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th id="message" class="table-col-width-small">
        <div class="p-d-flex p-jc-between p-ai-center">Name</div>
      </th>
      <th id="desc" class="table-col-width-small">
        <div class="p-d-flex p-jc-between p-ai-center">Description</div>
      </th>
      <th id="type" class="table-col-width-small">
        <div class="p-d-flex p-jc-between p-ai-center">Type</div>
      </th>
      <th id="stars" class="table-col-width-small">
        <div class="p-d-flex p-jc-between p-ai-center">Stars</div>
      </th>
      <th id="schedule" class="table-col-width-small">
        <div class="p-d-flex p-jc-between p-ai-center">Schedule</div>
      </th>
      <th id="actions" class="table-col-width-large">
        <div class="p-d-flex p-jc-between p-ai-center">Actions</div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-activity>
    <tr>
      <td>
        <span>{{ activity.name }}</span>
      </td>
      <td class="truncate-text">
        <span>{{ activity.activityTypeDescription }}</span>
      </td>
      <td>
        <span>{{ getType(activity.activityTypeLookupId) }}</span>
      </td>
      <td>
        <span>{{ activity.stars }}</span>
      </td>
      <td>
        <span>{{ activity.activityScheduleDescription }}</span>
      </td>
      <td>
        <p-button
          label="Edit"
          styleClass="p-button-warning p-button-sm"
          icon="pi pi-file-edit"
          iconPos="right"
          (onClick)="showEditActivity(activity)"
        ></p-button>
        <p-button
          *ngIf="activity.isActive"
          label="Disable"
          styleClass="p-button-danger p-button-sm"
          icon="pi pi-lock"
          iconPos="right"
          (onClick)="swapEnableActivity(activity)"
        ></p-button>
        <p-button
          *ngIf="!activity.isActive"
          label="Enable"
          styleClass="p-button-primary p-button-sm"
          icon="pi pi-lock-open"
          iconPos="right"
          (onClick)="swapEnableActivity(activity)"
        ></p-button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7">No activities found.</td>
    </tr>
  </ng-template>
</p-table>
