import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class AppSpinnerComponent implements OnInit {
  constructor(public notification: NotificationService) {
    this.notification.showSpinner.subscribe();
  }

  ngOnInit(): void {}
}
