<div class="navbar-background">
  <p-menubar [model]="items">
    <ng-template pTemplate="start">
      <img
        src="../../../assets/img/logo.png"
        class="mr-2 desktopLogo"
        alt="KidsGate Logo"
      />
    </ng-template>
    <ng-template pTemplate="end">
      <p-button
        label="Logout"
        icon="pi pi-sign-out"
        (click)="userService.logout()"
        styleClass="p-button-secondary p-button-text desktopOnly"
      ></p-button>
    </ng-template>
  </p-menubar>
</div>
