import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Entertainment } from '../../models/entertainment.model';

@Component({
  selector: 'app-add-reward',
  templateUrl: './add-reward.component.html',
  styleUrls: ['./add-reward.component.scss'],
})
export class AddRewardComponent {
  public inputGroup: FormGroup;
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    this.inputGroup = new FormGroup({
      coupon: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
      ]),
      stars: new FormControl(0, [Validators.required]),
      description: new FormControl('', [Validators.required]),
    });
  }

  public addReward(): void {
    let newReward: Entertainment = {
      stars: 0,
      coupon: '',
      description: '',
      isActive: true,
    };

    newReward.coupon = this.inputGroup.get('coupon')?.value;
    newReward.stars = this.inputGroup.get('stars')?.value;
    newReward.description = this.inputGroup.get('description')?.value;

    this.ref.close(newReward);
  }

  public cancelAddReward(): void {
    this.ref.close();
  }
}
