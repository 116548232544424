<div>
  <form [formGroup]="ParentGroup" (submit)="editParent()">
    <div class="row">
      <div class="col-sm-2">
        <label for="firstName">First Name: </label>
      </div>
      <div class="col-sm-10">
        <input
          id="firstName"
          #firstName
          type="text"
          formControlName="firstName"
          pInputText
        />
        <small
          style="color: red"
          *ngIf="
            !ParentGroup.get('firstName')?.valid &&
            ParentGroup.get('firstName')?.dirty
          "
          >Please enter your first name.</small
        >
      </div>
    </div>

    <div class="clearfix visible-xs"></div>
    <div class="row">
      <div class="col-sm-2">
        <label for="lastName">Last Name: </label>
      </div>
      <div class="col-sm-10">
        <input
          id="lastName"
          #lastName
          type="text"
          formControlName="lastName"
          pInputText
        />
        <small
          style="color: red"
          *ngIf="
            !ParentGroup.get('lastName')?.valid &&
            ParentGroup.get('lastName')?.dirty
          "
          >Please enter your last name.</small
        >
      </div>
    </div>
    <div class="clearfix visible-xs"></div>
    <div class="row">
      <div class="col-sm-2">
        <label for="address1">Address 1: </label>
      </div>
      <div class="col-sm-10">
        <input
          id="address1"
          #address1
          type="text"
          formControlName="address1"
          pInputText
        />
        <small
          style="color: red"
          *ngIf="
            !ParentGroup.get('address1')?.valid &&
            ParentGroup.get('address1')?.dirty
          "
          >Please enter address.</small
        >
      </div>
    </div>

    <div class="clearfix visible-xs"></div>
    <div class="row">
      <div class="col-sm-2">
        <label for="address2">Address 2: </label>
      </div>
      <div class="col-sm-10">
        <input
          id="address2"
          #address2
          type="text"
          formControlName="address2"
          pInputText
        />
      </div>
    </div>

    <div class="clearfix visible-xs"></div>
    <div class="to-right flex">
      <p-button
        label="Cancel"
        styleClass="p-button-danger"
        (onClick)="cancelEdit()"
      ></p-button>
      <p-button
        label="Save"
        type="Submit"
        [disabled]="!ParentGroup.valid"
      ></p-button>
    </div>
  </form>
</div>
