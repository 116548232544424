import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { PrimeNGConfig } from 'primeng/api';
import { EditComponent } from './components/edit-user/edit.component';
import { User } from './model/user.model';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DeleteComponent } from './components/delete-user/delete.component';
import { SendNotificationComponent } from './components/send-notification/send-notification.component';
import { NotificationService } from 'src/app/services/notification.service';
import { SendMassNotificationComponent } from './components/send-mass-notification/send-mass-notification.component';
import { getUsersRequest } from './model/getUsersRequest';
import { ChildDto } from './model/userDto.model';
import { EditChildUserComponent } from './components/edit-child-user/edit-child-user.component';

@Component({
  selector: 'app-usermanagement',
  templateUrl: './usermanagement.component.html',
  styleUrls: ['./usermanagement.component.scss'],
  providers: [DialogService],
})
export class UsermanagementComponent implements OnInit {
  userRequest: getUsersRequest = {
    PageNumber: 1,
    PageSize: 10,
    SearchText: '',
    TotalCount: 0,
  };
  public children: ChildDto[];
  public selectedUsers: User[];
  public ref: DynamicDialogRef = new DynamicDialogRef();

  constructor(
    public dataService: DataService,
    private primengConfig: PrimeNGConfig,
    public dialogService: DialogService,
    public notification: NotificationService
  ) {
    this.children = [];
    this.selectedUsers = [];
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.getAllUsers();
  }

  public showEdit(userFromRow: ChildDto): void {
    const ref = this.dialogService.open(EditChildUserComponent, {
      data: {
        user: userFromRow,
      },
      header: 'Edit User ' + userFromRow.firstName + ' ' + userFromRow.lastName,
      width: '70%',
      baseZIndex: 10000,
      maximizable: false,
    });
    ref.onClose.subscribe((res) => {
      if (res && res.user !== null) {
        this.children = this.children.map((child) =>
          child.id === res.user.id ? res.user : child
        );
      }
    });
  }
  public showSwapDisable(userFromRow: ChildDto): void {
    if (userFromRow.isDisabled === false) {
      this.ref = this.dialogService.open(DeleteComponent, {
        header: 'Disable/Enable User',
        width: '70%',
        baseZIndex: 10000,
        maximizable: false,
      });

      this.ref.onClose.subscribe((user) => {
        if (user === true) {
          this.SwapIsDisabledUer(userFromRow.id);
        }
      });
    } else {
      this.SwapIsDisabledUer(userFromRow.id);
    }
  }

  private SwapIsDisabledUer(userId: number): void {
    this.dataService
      .disableUserById(
        `api/admin/users/UserManagement/SwapIsDisableKidPortalUser`,
        userId
      )
      .subscribe((data) => {
        this.notification.showSpinner.next(false);
        this.getAllUsers();
      });
  }

  private getAllUsers(): void {
    this.dataService
      .add('api/admin/users/UserManagement/GetAllChildren', this.userRequest)
      .subscribe((res: any) => {
        this.notification.showSpinner.next(false);
        this.children = res;
        if (res && res.length > 0) {
          this.userRequest.TotalCount = res[0].totalCount;
        } else {
          this.userRequest.TotalCount = 0;
          this.userRequest.PageNumber = 1;
          this.userRequest.PageSize = 10;
        }
      });
  }
  public showSendNotification(userFromRow: User): void {
    this.ref = this.dialogService.open(SendNotificationComponent, {
      data: {
        user: userFromRow,
      },
      header: 'Send Notification',
      width: '70%',
      baseZIndex: 10000,
      maximizable: false,
    });
  }

  public showSendMassNotification(): void {
    this.ref = this.dialogService.open(SendMassNotificationComponent, {
      data: {
        users: this.selectedUsers,
      },
      header: 'Send Notification',
      width: '70%',
      baseZIndex: 10000,
      maximizable: false,
    });
  }

  handlePagination(event: any) {
    this.userRequest.PageNumber = event.page + 1;
    this.getAllUsers();
  }

  public onInputChange(searchText: string): void {
    this.userRequest.SearchText = searchText;
    this.userRequest.PageNumber = 1;
    this.getAllUsers();
  }
  public onClearSearch(searchText: string): void {
    if (searchText.length === 0) {
      this.userRequest.SearchText = '';
      this.userRequest.PageNumber = 1;
      this.getAllUsers();
    }
  }
}
