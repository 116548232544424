import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DataService } from 'src/app/services/data.service';
import { NotificationService } from 'src/app/services/notification.service';
import { inspirationalMessage } from '../../models/inspirational-message.model';
import { AddInspirationalMessageComponent } from '../add-inspirational-message/add-inspirational-message.component';
import { EditInspirationalMessageComponent } from '../edit-inspirational-message/edit-inspirational-message.component';
import { DeleteInspirationalMessageComponent } from '../delete-inspirational-message/delete-inspirational-message.component';
import { StatusPipe } from 'src/app/pipes/status.pipe';

@Component({
  selector: 'app-inspirational-messages',
  templateUrl: './inspirational-messages.component.html',
  styleUrls: ['./inspirational-messages.component.scss'],
})
export class InspirationalMessagesComponent implements OnInit {
  public ref: DynamicDialogRef = new DynamicDialogRef();
  public inspirationalMessages: inspirationalMessage[];

  constructor(
    public dataService: DataService,
    private primengConfig: PrimeNGConfig,
    public dialogService: DialogService,
    public notification: NotificationService
  ) {
    this.inspirationalMessages = [];
  }

  ngOnInit(): void {
    this.dataService
      .getAll<inspirationalMessage>(
        'api/admin/webportal/inspirationalMessage/GetAll'
      )
      .subscribe((inspirationalMessages) => {
        this.inspirationalMessages = inspirationalMessages;
        this.notification.showSpinner.next(false);
      });
  }

  public showAddInspirationalMessage(): void {
    this.ref = this.dialogService.open(AddInspirationalMessageComponent, {
      header: 'Add Inspirational Message',
      width: '70%',
      baseZIndex: 10000,
      maximizable: false,
    });

    this.ref.onClose.subscribe((newEntry) => {
      if (newEntry != null) {
        let newInspirationalMessage: inspirationalMessage = newEntry;
        this.inspirationalMessages.push(newInspirationalMessage);
        this.dataService
          .add(
            'api/admin/webportal/inspirationalMessage/add',
            newInspirationalMessage
          )
          .subscribe(() => {
            this.notification.showSpinner.next(false);

            this.dataService
              .getAll<inspirationalMessage>(
                'api/admin/webportal/inspirationalMessage/GetAll'
              )
              .subscribe((inspirationalMessages) => {
                this.inspirationalMessages = inspirationalMessages;
                this.notification.showSpinner.next(false);
              });
          });
      }
    });
  }

  public showEditInspirationalMessage(row: inspirationalMessage): void {
    this.ref = this.dialogService.open(EditInspirationalMessageComponent, {
      data: {
        inspirationalMessage: row,
      },
      header: 'Edit Inspirational Message',
      width: '70%',
      baseZIndex: 10000,
      maximizable: false,
    });

    this.ref.onClose.subscribe((edited) => {
      if (edited != null) {
        this.dataService
          .update('api/admin/webportal/inspirationalMessage/Edit', edited)
          .subscribe(() => {
            this.notification.showSpinner.next(false);

            this.dataService
              .getAll<inspirationalMessage>(
                'api/admin/webportal/inspirationalMessage/GetAll'
              )
              .subscribe((inspirationalMessages) => {
                this.inspirationalMessages = inspirationalMessages;
                this.notification.showSpinner.next(false);
              });
          });
      }
    });
  }

  public swapEnableInspirationalMessage(row: inspirationalMessage): void {
    row.isActive = row.isActive ? false : true;

    this.dataService
      .update('api/admin/webportal/InspirationalMessage/edit', row)
      .subscribe(() => {
        this.notification.showSpinner.next(false);

        this.dataService
          .getAll<inspirationalMessage>(
            'api/admin/webportal/inspirationalMessage/GetAll'
          )
          .subscribe((inspirationalMessages) => {
            this.inspirationalMessages = inspirationalMessages;
            this.notification.showSpinner.next(false);
          });
      });
  }

  public showDeleteInspirationalMessage(row: inspirationalMessage): void {
    this.ref = this.dialogService.open(DeleteInspirationalMessageComponent, {
      header: 'Delete Inspirational Message',
      width: '70%',
      baseZIndex: 10000,
      maximizable: false,
    });

    this.ref.onClose.subscribe((ifDelete) => {
      if (ifDelete) {
        this.dataService
          .deleteById(
            'api/admin/webportal/InspirationalMessage/Delete',
            row.id as number
          )
          .subscribe(() => {
            this.notification.showSpinner.next(false);

            this.dataService
              .getAll<inspirationalMessage>(
                'api/admin/webportal/inspirationalMessage/GetAll'
              )
              .subscribe((inspirationalMessages) => {
                this.inspirationalMessages = inspirationalMessages;
                this.notification.showSpinner.next(false);
              });
          });
      }
    });
  }

  public booleanToActive(isActive: boolean): string {
    if (isActive) {
      return 'Active';
    } else {
      return 'Not Active';
    }
  }
}
