import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DataService } from 'src/app/services/data.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ChildDto } from '../../model/userDto.model';

@Component({
  selector: 'app-edit-child-user',
  templateUrl: './edit-child-user.component.html',
  styleUrls: ['./edit-child-user.component.scss'],
})
export class EditChildUserComponent implements OnInit {
  public kidsGroup!: FormGroup;
  public user: ChildDto;
  public header!: string;

  constructor(
    public dataService: DataService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public notification: NotificationService,
    private fb: FormBuilder
  ) {
    this.user = this.config.data.user;

    if (this.user.auth0UserId !== null) {
      this.kidsGroup = this.fb.group({
        firstName: [this.user.firstName, [Validators.required]],
        lastName: [this.user.lastName, [Validators.required]],
        gender: [this.user.gender, [Validators.required]],
        dateOfBirth: [
          this.user.dateOfBirth?.toString().substring(0, 10),
          [Validators.required],
        ],
      });
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.header = 'Edit user: ';
      this.config.header = this.header.concat(
        this.user.firstName!,
        ' ',
        this.user.lastName!
      );
    });
  }

  public editKid(): void {
    if (this.kidsGroup.valid) {
      this.user.firstName = this.kidsGroup.get('firstName')?.value;
      this.user.lastName = this.kidsGroup.get('lastName')?.value;
      this.user.dateOfBirth = this.kidsGroup.get('dateOfBirth')?.value;

      this.dataService
        .update('api/admin/users/UserManagement/EditKidPortalUser', this.user)
        .subscribe((response) => {
          this.notification.showSpinner.next(false);
        });

      this.ref.close({ user: this.user });
    }
  }

  public cancelEdit(): void {
    this.ref.close({ user: null });
  }
}
