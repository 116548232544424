import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Coupon } from '../../models/coupon.model';
import { DataService } from 'src/app/services/data.service';
import { PrimeNGConfig } from 'primeng/api';
import { NotificationService } from 'src/app/services/notification.service';
import { AddCouponComponent } from '../add-coupon/add-coupon.component';
import { EditCouponComponent } from '../edit-coupon/edit-coupon.component';
import { DeleteCouponComponent } from '../delete-coupon/delete-coupon.component';
import { StatusPipe } from 'src/app/pipes/status.pipe';

@Component({
  selector: 'app-coupon-management',
  templateUrl: './coupon-management.component.html',
  styleUrls: ['./coupon-management.component.scss'],
})
export class CouponManagementComponent implements OnInit {
  public ref: DynamicDialogRef = new DynamicDialogRef();
  public couponCodes: Coupon[];

  constructor(
    public dataService: DataService,
    private primengConfig: PrimeNGConfig,
    public dialogService: DialogService,
    public notification: NotificationService
  ) {
    this.couponCodes = [];
  }

  ngOnInit(): void {
    this.dataService
      .getAll<Coupon>('api/admin/WebPortal/CouponManagement/GetAll')
      .subscribe((coupons) => {
        this.couponCodes = coupons;
        this.notification.showSpinner.next(false);
      });
  }

  public showAddCoupon(): void {
    this.ref = this.dialogService.open(AddCouponComponent, {
      header: 'Add Coupon',
      width: '70%',
      baseZIndex: 10000,
      maximizable: false,
    });

    this.ref.onClose.subscribe((newCoupon) => {
      if (newCoupon != null) {
        this.dataService
          .add('api/admin/WebPortal/CouponManagement/Add', newCoupon)
          .subscribe(() => {
            this.notification.showSpinner.next(false);
            this.dataService
              .getAll<Coupon>('api/admin/WebPortal/CouponManagement/GetAll')
              .subscribe((coupons) => {
                this.couponCodes = coupons;
                this.notification.showSpinner.next(false);
              });
          });
      }
    });
  }

  public showEditCoupon(coupon: Coupon): void {
    this.ref = this.dialogService.open(EditCouponComponent, {
      data: {
        coupon: coupon,
      },
      header: 'Add Coupon',
      width: '70%',
      baseZIndex: 10000,
      maximizable: false,
    });

    this.ref.onClose.subscribe((edited) => {
      if (edited != null) {
        this.dataService
          .update('api/admin/WebPortal/CouponManagement/Edit', edited)
          .subscribe(() => {
            this.notification.showSpinner.next(false);
            this.dataService
              .getAll<Coupon>('api/admin/WebPortal/CouponManagement/GetAll')
              .subscribe((coupons) => {
                this.couponCodes = coupons;
                this.notification.showSpinner.next(false);
              });
          });
      }
    });
  }

  public swapEnableCoupon(row: Coupon): void {
    row.isActive = row.isActive ? false : true;

    this.dataService
      .update('api/admin/WebPortal/CouponManagement/Edit', row)
      .subscribe(() => {
        this.notification.showSpinner.next(false);
        this.dataService
          .getAll<Coupon>('api/admin/WebPortal/CouponManagement/GetAll')
          .subscribe((coupons) => {
            this.couponCodes = coupons;
            this.notification.showSpinner.next(false);
          });
      });
  }

  public showDeleteCoupon(coupon: Coupon): void {
    this.ref = this.dialogService.open(DeleteCouponComponent, {
      header: 'Delete Coupon',
      width: '70%',
      baseZIndex: 10000,
      maximizable: false,
    });

    this.ref.onClose.subscribe((successfulDelete) => {
      if (successfulDelete) {
        this.dataService
          .deleteById(
            'api/admin/WebPortal/CouponManagement/Delete',
            coupon.id as number
          )
          .subscribe(() => {
            this.notification.showSpinner.next(false);
            this.dataService
              .getAll<Coupon>('api/admin/WebPortal/CouponManagement/GetAll')
              .subscribe((coupons) => {
                this.couponCodes = coupons;
                this.notification.showSpinner.next(false);
              });
          });
      }
    });
  }
}
