import { Component } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { DataService } from 'src/app/services/data.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SubscriptionOptionMap } from '../../models/subscription-option-map.model';

class matrix {
  public plan?: string;
  public options?: string;
}

@Component({
  selector: 'app-view-plan-matrix',
  templateUrl: './view-plan-matrix.component.html',
  styleUrls: ['./view-plan-matrix.component.scss'],
})
export class ViewPlanMatrixComponent {
  public ref: DynamicDialogRef = new DynamicDialogRef();
  public subscriptionsWithOption: SubscriptionOptionMap[];
  public subscriptionsWithTextOptions: matrix[];

  constructor(
    public dataService: DataService,
    public notification: NotificationService,
    private primengConfig: PrimeNGConfig,
    public dialogService: DialogService,
    public config: DynamicDialogConfig
  ) {
    this.subscriptionsWithOption = config.data.subscriptionsWithOptions;
    this.subscriptionsWithTextOptions = [];

    this.subscriptionsWithOption.forEach((plan) => {
      let temp = new matrix();
      temp.plan = plan.description;
      temp.options = '';
      plan.SubscriptionOption?.forEach((option) => {
        temp.options += option.text + ', ';
      });
      temp.options = temp.options?.substring(0, temp.options.length - 2);
      this.subscriptionsWithTextOptions.push(temp);
    });

    console.log(this.subscriptionsWithTextOptions);
  }
}
