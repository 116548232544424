import { Component } from '@angular/core';
import { SubscriptionPlan } from '../../models/subscription-plan.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';

interface Options {
  name: string;
  active: boolean;
}

@Component({
  selector: 'app-add-subscription-plan',
  templateUrl: './add-subscription-plan.component.html',
  styleUrls: ['./add-subscription-plan.component.scss'],
})
export class AddSubscriptionPlanComponent {
  public newSubscriptionPlan = new SubscriptionPlan();
  public inputGroup: FormGroup;
  public activeOptions: Options[];

  constructor(
    private primengConfig: PrimeNGConfig,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    this.activeOptions = [
      { name: 'Active', active: true },
      { name: 'Not Active', active: false },
    ];
    this.inputGroup = new FormGroup({
      description: new FormControl('', [Validators.required]),
      cost: new FormControl('', [Validators.required]),
      isActive: new FormControl('', [Validators.required]),
    });
  }

  public addSubscriptionPlan(): void {
    this.newSubscriptionPlan.isActive =
      this.inputGroup.get('isActive')?.value.active;
    this.newSubscriptionPlan.cost = this.inputGroup.get('cost')?.value;
    this.newSubscriptionPlan.description =
      this.inputGroup.get('description')?.value;
    this.ref.close(this.newSubscriptionPlan);
  }

  public cancelSubscriptionPlan(): void {
    this.ref.close();
  }
}
