<form [formGroup]="inputGroup" (submit)="editKidsWellnessProfessional()">
  <div class="row">
    <div class="col-sm-2">
      <label for="wellnessProfessional"
        >Available Wellness Professionals:</label
      >
    </div>
    <div class="col-sm-10">
      <p-dropdown
        formControlName="wellnessProfessional"
        [options]="fullWellnessProfessionalList"
        optionLabel="fullName"
        placeholder="Choose Wellness Professional"
        [style]="{ width: '100%', marginBottom: '10px' }"
      ></p-dropdown>
      <small
        style="color: red"
        *ngIf="
          !inputGroup.get('wellnessProfessional')?.valid &&
          inputGroup.get('wellnessProfessional')?.dirty
        "
        >Please choose a wellness professional.</small
      >
    </div>
  </div>

  <div class="clearfix visible-xs"></div>
  <div class="row">
    <p>
      All history, notes, and documents associated with this child will be moved
      to the new wellness professional after confirming.
    </p>
  </div>

  <div class="clearfix visible-xs"></div>
  <div class="to-right flex">
    <p-button
      label="Cancel"
      styleClass="p-button-danger"
      (onClick)="cancelEdit()"
    ></p-button>
    <p-button
      label="Edit Wellness Professional"
      type="Submit"
      [disabled]="!inputGroup.valid"
    ></p-button>
  </div>
</form>
