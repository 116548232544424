<div class="flex">
  <p class="display-6">Please confirm Disable</p>
  <p>
    Please be aware, this task will Disable the user due to which user will not
    able to login to the portal.
  </p>
  <p>This action can be undone.</p>

  <div class="clearfix visible-xs"></div>
  <div class="to-right flex">
    <p-button
      label="Cancel"
      icon="pi pi-times"
      iconPos="right"
      (onClick)="deleteCommand(false)"
    ></p-button>
    <p-button
      label="Disable"
      styleClass="p-button-danger"
      icon="pi pi-trash"
      iconPos="right"
      (onClick)="deleteCommand(true)"
    ></p-button>
  </div>
</div>
