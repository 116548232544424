import { Component } from '@angular/core';
import { Coupon } from '../../models/coupon.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';

interface Options {
  name: string;
  active: boolean;
}

@Component({
  selector: 'app-edit-coupon',
  templateUrl: './edit-coupon.component.html',
  styleUrls: ['./edit-coupon.component.scss'],
})
export class EditCouponComponent {
  public inputGroup: FormGroup;
  public coupon: Coupon;
  public activeOptions: Options[];

  constructor(
    private primengConfig: PrimeNGConfig,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    this.activeOptions = [
      { name: 'Active', active: true },
      { name: 'Not Active', active: false },
    ];
    this.coupon = config.data.coupon;
    this.inputGroup = new FormGroup({
      couponCode: new FormControl(this.coupon.couponCode, [
        Validators.required,
      ]),
      isActive: new FormControl('', [Validators.required]),
      amount: new FormControl(this.coupon.amount, [Validators.required]),
      expirationDate: new FormControl(
        this.coupon.expirationDate?.toString().substring(0, 10),
        [Validators.required]
      ),
    });

    if (this.coupon.isActive) {
      this.inputGroup.get('isActive')?.setValue(this.activeOptions[0]);
    } else {
      this.inputGroup.get('isActive')?.setValue(this.activeOptions[1]);
    }
  }

  public editCoupon(): void {
    let edited: Coupon = new Coupon();
    edited.couponCode = this.inputGroup.get('couponCode')?.value;
    edited.expirationDate = this.inputGroup.get('expirationDate')?.value;
    edited.amount = this.inputGroup.get('amount')?.value;
    edited.id = this.coupon.id;
    edited.dbRowVersion = this.coupon.dbRowVersion;
    if (this.inputGroup.get('isActive')?.value.active) {
      edited.isActive = true;
    } else {
      edited.isActive = false;
    }

    this.ref.close(edited);
  }

  public cancelEditCoupon(): void {
    this.ref.close();
  }
}
