<p-table
  #dt2
  [value]="subscriptionPlans"
  dataKey="id"
  [rows]="10"
  styleClass="p-datatable-users p-datatable-striped"
  [globalFilterFields]="['cost', 'description', 'status']"
  [scrollable]="true"
  scrollHeight="1000px"
  dataKey="subscriptionPlans"
  [tableStyle]="{ 'min-width': '50rem' }"
>
  <ng-template pTemplate="caption">
    <div class="flex">
      <span class="p-input-icon-right to-right">
        <p-button
          label="Add Subscription Plan"
          icon="pi pi-user"
          styleClass="p-button-success"
          iconPos="right"
          (onClick)="showAddSubscriptionPlan()"
        ></p-button>
        <i class="pi pi-search to-right"></i>
        <input
          pInputText
          type="text"
          (input)="dt2.filterGlobal($any($event.target).value, 'contains')"
          placeholder="Search keyword"
        />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th id="optionText" class="table-col-width-small">
        <div class="p-d-flex p-jc-between p-ai-center">Name</div>
      </th>
      <th id="optionText" class="table-col-width-small">
        <div class="p-d-flex p-jc-between p-ai-center">Cost</div>
      </th>
      <th id="optionText" class="table-col-width-small">
        <div class="p-d-flex p-jc-between p-ai-center">Status</div>
      </th>
      <th id="actions" class="table-col-width-large">
        <div class="p-d-flex p-jc-between p-ai-center">Actions</div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-subscriptionPlan>
    <tr>
      <td>
        <span>{{ subscriptionPlan.description }}</span>
      </td>
      <td>
        <span>{{ subscriptionPlan.cost }}</span>
      </td>
      <td>
        <span>{{ subscriptionPlan.isActive | status }}</span>
      </td>
      <td>
        <p-button
          label="Edit"
          styleClass="p-button-warning p-button-sm"
          icon="pi pi-file-edit"
          iconPos="right"
          (onClick)="showEditSubscriptionPlan(subscriptionPlan)"
        ></p-button>
        <p-button
          *ngIf="subscriptionPlan.isActive"
          label="Disable"
          styleClass="p-button-danger p-button-sm"
          icon="pi pi-lock"
          iconPos="right"
          (onClick)="swapEnableSubscriptionPlan(subscriptionPlan)"
        ></p-button>
        <p-button
          *ngIf="!subscriptionPlan.isActive"
          label="Enable"
          styleClass="p-button-primary p-button-sm"
          icon="pi pi-lock-open"
          iconPos="right"
          (onClick)="swapEnableSubscriptionPlan(subscriptionPlan)"
        ></p-button>
        <p-button
          label="Delete"
          styleClass="p-button-danger p-button-sm"
          icon="pi pi-trash"
          iconPos="right"
          (onClick)="showDeleteSubscriptionPlan(subscriptionPlan)"
        ></p-button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7">No subscription plans found.</td>
    </tr>
  </ng-template>
</p-table>
