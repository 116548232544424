<form [formGroup]="inputGroup" (submit)="editActivity()">
  <div class="row">
    <div class="col-sm-2">
      <label for="name">Name:</label>
    </div>
    <div class="col-sm-10">
      <input id="name" type="text" pInputText formControlName="name" />
      <small
        style="color: red"
        *ngIf="
          !inputGroup.get('name')?.valid &&
          (inputGroup.get('name')?.dirty || inputGroup.get('name')?.touched)
        "
        >Please enter the name of the activity.</small
      >
    </div>
  </div>

  <div class="row">
    <div class="col-sm-2">
      <label for="type">Type:</label>
    </div>
    <div class="col-sm-10">
      <p-dropdown
        formControlName="type"
        [options]="typeOptions"
        optionLabel="type"
        placeholder="Choose Type"
        [style]="{ width: '100%', marginBottom: '10px' }"
      ></p-dropdown>
      <small
        style="color: red"
        *ngIf="
          !inputGroup.get('type')?.valid &&
          (inputGroup.get('type')?.dirty || inputGroup.get('type')?.touched)
        "
        >Please enter a valid type.</small
      >
    </div>
  </div>
  <div class="row">
    <div class="col-sm-2">
      <label for="description">Type Description:</label>
    </div>
    <div class="col-sm-10">
      <input
        id="description"
        type="text"
        pInputText
        formControlName="description"
      />
      <small
        style="color: red"
        *ngIf="
          !inputGroup.get('description')?.valid &&
          (inputGroup.get('description')?.dirty ||
            inputGroup.get('description')?.touched)
        "
        >Please enter a description.</small
      >
    </div>
  </div>
  <div
    class="row mb-3"
    *ngIf="this.inputGroup.get('type')?.value.type === 'Nutrition'"
  >
    <div class="col-sm-2">
      <label for="description">Recipe Steps:</label>
    </div>
    <div class="col-sm-10">
      <tag-input
        formControlName="recipeSteps"
        secondaryPlaceholder="Press Enter to Add Steps"
        pInputText
        [modelAsStrings]="true"
      >
      </tag-input>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-2">
      <label for="schedule">Schedule:</label>
    </div>
    <div class="col-sm-10">
      <p-dropdown
        formControlName="schedule"
        [options]="scheduleOptions"
        placeholder="Choose Schedule"
        [style]="{ width: '100%', marginBottom: '10px' }"
      ></p-dropdown>
      <small
        style="color: red"
        *ngIf="
          !inputGroup.get('schedule')?.valid &&
          (inputGroup.get('schedule')?.dirty ||
            inputGroup.get('schedule')?.touched)
        "
        >Please enter a valid schedule.</small
      >
    </div>
  </div>

  <div class="row" *ngIf="this.inputGroup.get('schedule')?.value === 'Weekly'">
    <div class="col-sm-2">
      <label for="dayOfWeek">Day of the Week:</label>
    </div>
    <div class="col-sm-10">
      <p-dropdown
        formControlName="dayOfWeek"
        [options]="daysOfWeek"
        placeholder="Choose day of the week"
        [style]="{ width: '100%', marginBottom: '10px' }"
      ></p-dropdown>
      <small
        style="color: red"
        *ngIf="
          !inputGroup.get('dayOfWeek')?.valid &&
          (inputGroup.get('dayOfWeek')?.dirty ||
            inputGroup.get('dayOfWeek')?.touched)
        "
        >Please choose which day of the week your activity is to be
        assigned.</small
      >
    </div>
  </div>

  <div class="row" *ngIf="this.inputGroup.get('schedule')?.value === 'Monthly'">
    <div class="col-sm-2">
      <label for="dayOfMonth">Day of the Month:</label>
    </div>
    <div class="col-sm-10">
      <p-dropdown
        formControlName="dayOfMonth"
        [options]="daysOfMonth"
        optionLabel=""
        placeholder="Choose day of the month"
        [style]="{ width: '100%', marginBottom: '10px' }"
      ></p-dropdown>
      <small
        style="color: red"
        *ngIf="
          !inputGroup.get('dayOfMonth')?.valid &&
          (inputGroup.get('dayOfMonth')?.dirty ||
            inputGroup.get('dayOfMonth')?.touched)
        "
        >Please enter which day of the month your activity is to be
        assigned.</small
      >
    </div>
  </div>

  <div class="row">
    <div class="col-sm-2">
      <label for="stars">Stars:</label>
    </div>
    <div class="col-sm-10">
      <input id="stars" pInputText type="number" formControlName="stars" />
      <small
        style="color: red"
        *ngIf="
          !inputGroup.get('stars')?.valid &&
          (inputGroup.get('stars')?.dirty || inputGroup.get('stars')?.touched)
        "
        >Please enter a star value.</small
      >
    </div>
  </div>

  <div class="row">
    <div class="col-sm-2">
      <label for="videoUrl">Video Url (if applicable):</label>
    </div>
    <div class="col-sm-10">
      <input id="videoUrl" type="text" pInputText formControlName="videoUrl" />
    </div>
  </div>
  <div class="row flex align-items-center">
    <div class="col-sm-2">
      <label for="status">Image (if applicable):</label>
    </div>
    <div class="col-sm-8 align-self-end">
      <input
        id="upload"
        type="file"
        (change)="onFileSelected($event)"
        class="form-control border-0"
      />
    </div>
    <div class="col-sm-2 imageSection">
      <div *ngIf="selectedImage">
        <img [src]="selectedImage" class="selectedImage" alt="Selected Image" />
      </div>
    </div>
  </div>
  <div
    class="row"
    *ngIf="this.inputGroup.get('type')?.value.type === 'Nutrition'"
  >
    <div class="col-sm-2">
      <label for="question">Question:</label>
    </div>
    <div class="col-sm-10">
      <input id="question" type="text" pInputText formControlName="question" />
    </div>
  </div>
  <div class="row">
    <div class="col-sm-2">
      <label for="status">Status:</label>
    </div>
    <div class="col-sm-10">
      <p-dropdown
        formControlName="status"
        [options]="trueFalse"
        optionLabel="name"
        placeholder="Choose Status"
        [style]="{ width: '100%', marginBottom: '10px' }"
      ></p-dropdown>
      <small
        style="color: red"
        *ngIf="
          !inputGroup.get('status')?.valid &&
          (inputGroup.get('status')?.dirty || inputGroup.get('status')?.touched)
        "
        >Please enter a valid status.</small
      >
    </div>
  </div>
  <div class="clearfix visible-xs"></div>
  <div class="to-right flex">
    <p-button
      label="Cancel"
      styleClass="p-button-danger"
      (onClick)="cancelEditActivity()"
    ></p-button>
    <p-button
      label="Save"
      type="Submit"
      [disabled]="!inputGroup.valid"
    ></p-button>
  </div>
</form>
