import { Component } from '@angular/core';
import { User } from '../../model/user.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EmailAddress } from '../../model/email-address.model';
import { EmailRecipient } from '../../model/email-recipient.model';
import { DataService } from 'src/app/services/data.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-send-mass-notification',
  templateUrl: './send-mass-notification.component.html',
  styleUrls: ['./send-mass-notification.component.scss'],
})
export class SendMassNotificationComponent {
  public users!: User[];
  public message: string;
  public subject: string;
  public messageGroup: FormGroup;
  public header!: string;
  private cancelOperation: boolean;

  constructor(
    public dataservice: DataService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public notification: NotificationService
  ) {
    this.messageGroup = new FormGroup({
      subject: new FormControl('', [Validators.required]),
      message: new FormControl('', [Validators.required]),
    });
    this.users = config.data.users;
    this.message = '';
    this.subject = '';
    this.cancelOperation = false;
  }

  public cancelSend(): void {
    this.ref.close();
  }

  public sendMessage(): void {
    this.message = this.messageGroup.get('message')?.value;
    this.subject = this.messageGroup.get('subject')?.value;

    this.users.forEach((user) => {
      if (this.cancelOperation) {
        return;
      }

      const to: EmailAddress[] = [];
      to.push(new EmailAddress(user.email ?? '', user.email ?? ''));
      const recipient: EmailRecipient = new EmailRecipient(to);

      this.dataservice
        .add<EmailRecipient>(
          `api/common/notification/Send/${this.subject}/${this.message}`,
          recipient
        )
        .subscribe((data) => {
          this.notification.showSpinner.next(false);
        });
    });

    this.ref.close();
  }

  public onSubjectKeyUp(args: any): void {
    this.messageGroup.get('subject')?.setValue(args);
  }

  public onMessageKeyUp(args: any): void {
    this.messageGroup.get('message')?.setValue(args);
  }
}
