<form [formGroup]="inputGroup" (submit)="addInspirationalMessage()">
  <div class="row">
    <div class="col-sm-2">
      <label for="message">Message:</label>
    </div>
    <div class="col-sm-10">
      <textarea
        id="message"
        rows="5"
        cols="30"
        pInputTextarea
        formControlName="message"
      ></textarea>
      <small
        style="color: red"
        *ngIf="
          !inputGroup.get('message')?.valid &&
          (inputGroup.get('message')?.dirty ||
            inputGroup.get('message')?.touched)
        "
        >Please enter an inspirational message.</small
      >
    </div>
  </div>
  <div class="clearfix visible-xs"></div>
  <div class="to-right flex">
    <p-button
      label="Cancel"
      styleClass="p-button-danger"
      (onClick)="cancelAddMessage()"
    ></p-button>
    <p-button
      label="Add Inspirational Message"
      type="Submit"
      [disabled]="!inputGroup.valid"
    ></p-button>
  </div>
</form>
