<form [formGroup]="inputGroup" (submit)="addSubscriptionOption()">
  <div class="row">
    <div class="col-sm-2">
      <label for="text">Subscription Option Text:</label>
    </div>
    <div class="col-sm-10">
      <input id="text" pInputTextarea formControlName="text" />
      <small
        style="color: red"
        *ngIf="
          !inputGroup.get('text')?.valid &&
          (inputGroup.get('text')?.dirty || inputGroup.get('text')?.touched)
        "
        >Please enter a subscription option message.</small
      >
    </div>
  </div>
  <div class="clearfix visible-xs"></div>
  <div class="to-right flex">
    <p-button
      label="Cancel"
      styleClass="p-button-danger"
      (onClick)="cancelAddSubscriptionOption()"
    ></p-button>
    <p-button
      label="Add Subscription Option"
      type="Submit"
      [disabled]="!inputGroup.valid"
    ></p-button>
  </div>
</form>
