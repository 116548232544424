export class Activity {
  public activityMatrixId?: number;
  public activityType?: string;
  public activityTypeLookupId?: string;
  public activityScheduleId?: number;
  public activityScheduleType?: string;
  public activityTypeId?: number;
  public name?: string;
  public activityTypeDescription?: string;
  public activityScheduleDescription?: string;
  public dayOfWeek?: string;
  public dayOfMonth?: number;
  public videoUrl?: string;
  public stars?: number;
  public frequency?: number;
  public isActive?: boolean;
  public image?: any | null;
  public dbRowVersion?: string;
  public question?: string;
  public recipeStepsJson?: string;
}
