<div class="flex">
  <p class="display-6">Please confirm delete</p>
  <p>This action cannot be undone</p>

  <div class="clearfix visible-xs"></div>
  <div class="to-right flex">
    <p-button
      label="Cancel"
      icon="pi pi-times"
      iconPos="right"
      (onClick)="cancelDelete()"
    ></p-button>
    <p-button
      label="Delete"
      styleClass="p-button-danger"
      icon="pi pi-trash"
      iconPos="right"
      (onClick)="deleteCoupon()"
    ></p-button>
  </div>
</div>
