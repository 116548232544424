import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-add-activity-type-lookup',
  templateUrl: './add-activity-type-lookup.component.html',
  styleUrls: ['./add-activity-type-lookup.component.scss'],
})
export class AddActivityTypeLookupComponent {
  public inputGroup: FormGroup;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    this.inputGroup = new FormGroup({
      type: new FormControl('', [Validators.required]),
    });
  }

  public addType(): void {
    let type = this.inputGroup.get('type')?.value;
    this.ref.close(type);
  }

  public cancelAddType(): void {
    this.ref.close();
  }
}
